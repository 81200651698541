import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  hidden: {
    display: "none !important",
  },
  row: {
    ...Commons.flexRow,
    alignItems: "auto",
    gap: 18,
    [Commons.smallQuery]: {
      flexWrap: "wrap",
    },
  },
  leftContainer: {
    flex: 3,
    padding: "0 30px",
  },
  rightContainer: {
    flex: 2,
    backgroundColor: colors.grayBlack,
    padding: "0 30px",
    position: "relative",
    overflowY: "auto",
    [Commons.smallQuery]: {
      display: "none",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 2,
      width: "calc(100% - 60px)",
      height: "calc(100% - 60px)",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      padding: 30,
    },
  },
  rowSteps: {
    ...Commons.flexRow,
    margin: "42px 0",
    border: `1px solid ${colors.themeColor}`,
    borderRadius: 12,
    overflow: "hidden",
    [Commons.smallQuery]: {
      flexWrap: "wrap",
    },
  },
  stepButton: {
    ...Commons.defaultButton,
    color: colors.themeColor,
    textAlign: "center",
    fontSize: 15,
    fontWeight: "600",
    padding: "12px 12px",
    flex: 1,
    "&:hover": {
      backgroundColor: `${colors.themeColor}50`,
    },
  },
  selectedStep: {
    backgroundColor: `${colors.themeColor} !important`,
    color: colors.white,
  },
  errorStep: {
    backgroundColor: colors.red,
    color: colors.white,
  },
  loadTestButton: {
    ...Commons.defaultButton,
    color: colors.themeColor,
    marginBottom: 12,
  },
  titleForm: {
    fontSize: 30,
    color: colors.themeColor,
    fontWeight: "700",
    margin: "0 0 12px 0",
    flex: 1,
    [Commons.smallQuery]: {
      fontSize: 24,
    },
  },
  previewButton: {
    ...Commons.flexRow,
    ...Commons.defaultButton,
    borderBottom: `1px solid ${colors.themeColor}`,
    color: colors.themeColor,
    fontSize: 14,
    padding: "0 12px",
    display: "none",
    "& svg": {
      fontSize: 18,
      marginRight: 6,
    },
    "&:hover": {
      backgroundColor: `${colors.themeColor}50`,
    },
    [Commons.smallQuery]: {
      display: "flex",
    },
  },
  rowMoveButtons: {
    ...Commons.flexRow,
    justifyContent: "space-between",
    paddingBottom: 60,
  },
  beforeButton: {
    ...Commons.defaultButton,
    border: `1px solid ${colors.themeColor}`,
    color: colors.themeColor,
    textAlign: "center",
    fontSize: 18,
    fontWeight: "600",
    borderRadius: 24,
    padding: "12px 30px",
    "&:hover": {
      backgroundColor: `${colors.themeColor}50`,
    },
    [Commons.smallQuery]: {
      fontSize: 16,
      padding: "12px 24px",
    },
  },
  nextButton: {
    ...Commons.defaultButton,
    border: `1px solid ${colors.themeColor}`,
    color: colors.white,
    textAlign: "center",
    fontSize: 18,
    fontWeight: "600",
    borderRadius: 24,
    padding: "12px 30px",
    backgroundColor: colors.themeColor,
    "&:hover": {
      color: colors.themeColor,
      backgroundColor: `${colors.themeColor}50`,
    },
    [Commons.smallQuery]: {
      fontSize: 16,
      padding: "12px 24px",
    },
  },
  containerForm: {
    padding: "30px 0",
  },
  itemRowForm: {
    flex: 1,
  },
  containerTypes: {
    marginTop: 24,
  },
  contPreview: {
    width: "calc(100% - 60px)",
    backgroundColor: colors.white,
    overflow: "hidden",
    position: "absolute",
    left: 30,
    top: 0,
    [Commons.smallQuery]: {
      width: "100%",
      position: "relative",
      left: 0,
      borderRadius: 18,
    },
  },
  showPreview: {
    display: "block",
  },
  rowHeaderPreview: {
    ...Commons.flexRow,
    display: "none",
    backgroundColor: colors.grayLight,
    paddingLeft: 24,
    position: "relative",
    zIndex: 1,
    "& span": {
      flex: 1,
      color: colors.themeColor,
      fontWeight: "700",
      fontSize: 18,
    },
    "& button": {
      ...Commons.defaultButton,
      backgroundColor: colors.themeColor,
      color: colors.white,
      padding: "6px 12px",
    },
    [Commons.smallQuery]: {
      display: "flex",
    },
  },
  /////////////////
  "@keyframes slideInFromLeft": {
    "0%": {
      right: -12,
    },
    "90%": {
      right: -12,
    },
    "100%": {
      right: -100,
    },
  },
  introduction: {
    textAlign: "center",
    fontSize: 18,
    maxWidth: 900,
    margin: "0 auto",
    width: "90%",
    padding: "42px 0",
  },
  contForm: {
    width: "calc(90% - 60px)",
    maxWidth: 900,
    backgroundColor: colors.white,
    boxShadow: colors.shadow,
    borderRadius: 18,
    margin: "6px auto",
    padding: 30,
    overflow: "hidden",
  },
  labelInput: {
    margin: 0,
    marginBottom: 12,
    fontWeight: "600",
    fontSize: 18,
    "&.error": {
      color: colors.red,
    },
  },
  sublabelInput: {
    margin: 0,
    marginBottom: 18,
    fontSize: 16,
    textAlign: "center",
    opacity: 0.5,
  },
  linkLabelInput: {
    color: colors.themeColor,
    cursor: "pointer",
  },
  textInput: {
    marginBottom: 42,
  },
  autocompleteTextInput: {
    background: colors.white,
    borderRadius: 4,
    border: "1px solid rgb(226, 229, 237)",
    boxShadow: "inset 0px 1px 2px 0px rgba(102, 113, 123, 0.21)",
    width: "calc(100% - 42px)",
    outline: "none",
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: 18,
    padding: "16px 20px",
    color: colors.mainxText,
    marginBottom: 42,
    "&::placeholder": {
      color: colors.mainxText,
      opacity: 0.5,
    },
  },
  fileInput: {
    marginBottom: 42,
  },
  previewBanner: {
    width: 208,
    position: "relative",
    height: 240,
    objectFit: "cover",
    borderRadius: 12,
    boxShadow: colors.shadow,
    marginBottom: 12,
  },
  previewLogo: {
    position: "relative",
    minWidth: 90,
    maxWidth: 90,
    minHeight: 90,
    maxHeight: 90,
    objectFit: "cover",
    borderRadius: "50%",
    boxShadow: colors.shadow,
    marginBottom: 12,
  },
  dateInput: {
    marginBottom: 42,
    background: colors.white,
    borderRadius: 4,
    border: "1px solid rgb(226, 229, 237)",
    boxShadow: "inset 0px 1px 2px 0px rgba(102, 113, 123, 0.21)",
    width: "calc(100% - 42px)",
    outline: "none",
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: 18,
    padding: "16px 20px",
    color: colors.mainxText,
    "& label": {
      display: "none !important",
    },
    "& .MuiInputBase-root.MuiInput-root": {
      marginTop: 0,
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none",
    },
  },
  textArea: {
    width: "calc(100% - 48px)",
    height: 120,
    marginBottom: 42,
    border: `1px solid ${colors.grayLight}`,
    padding: 24,
    borderRadius: 12,
    boxShadow: colors.shadow,
    resize: "none",
  },
  rowButtons: {
    ...Commons.flexRow,
    marginBottom: 42,
  },
  buttonRow: {
    ...Commons.defaultButton,
    borderRadius: 12,
    backgroundColor: colors.white,
    boxShadow: colors.shadow,
    padding: "12px 24px",
    margin: "0px 12px",
    fontSize: 16,
  },
  saveButton: {
    ...Commons.defaultButton,
    backgroundColor: colors.themeColor,
    color: colors.white,
    borderRadius: 12,
    boxShadow: colors.shadow,
    fontWeight: "bold",
    width: "100%",
    textAlign: "center",
    padding: "12px 0",
    fontSize: 18,
  },

  blank: {
    height: 60,
  },
  linkSecretButton: {
    ...Commons.defaultButton,
    ...Commons.centerHorizontal,
    backgroundColor: colors.gray50,
    color: colors.white,
    textAlign: "center",
    padding: "12px 0",
    borderRadius: 12,
    boxShadow: colors.shadow,
    marginBottom: 60,
    width: "100%",
    fontSize: 16,
  },
  contPrices: {
    marginBottom: 42,
    overflowX: "auto",
    overflowY: "visible",
    padding: "0 6px 12px 6px",
    maxWidth: "calc(100vw - 72px)",
  },
  rowInputPrice: {
    ...Commons.flexRow,
    padding: 6,
    gap: 12,
    marginTop: 12,
    minWidth: 426,
    alignItems: "flex-end",
  },
  itemInputPrice: {
    flex: 1,
  },
  labelInputPrice: {
    fontSize: 16,
    fontWeight: "600",
  },
  inputPrice: {
    marginTop: 6,
    width: "100%",
  },
  inputPriceCreated: {
    marginTop: 6,
    flex: 1,
  },
  buttonPrice: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    color: colors.primary,
    padding: 6,
    "& svg": {
      fontSize: 36,
    },
  },
  rowInfoPrice: {
    ...Commons.flexRow,
    boxShadow: colors.shadow,
    borderRadius: 12,
    padding: "6px 36px 6px 24px",
    gap: 12,
    marginTop: 12,
    border: `2px solid transparent`,
    position: "relative",
    minWidth: 400,
  },
  currentPhasePrice: {
    borderColor: colors.themeColor,
  },
  nextPhasePrice: {
    borderColor: colors.black,
  },
  floatLabelPrice: {
    ...Commons.centerHorizontal,
    backgroundColor: colors.themeColor,
    color: colors.white,
    textAlign: "center",
    position: "absolute",
    bottom: -12,
    zIndex: 1,
    fontSize: 16,
    padding: "0px 12px",
    borderRadius: 12,
  },
  bgBlack: {
    backgroundColor: colors.black,
  },
  textInfoPrice: {
    flex: 1,
    fontSize: 18,
    maring: 0,
  },
  textAddedLink: {
    fontSize: 12,
    maring: "0 6px",
    color: colors.gray50,
  },
  buttonInfoPrice: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    color: colors.red,
    padding: 6,
    "& svg": {
      fontSize: 28,
    },
  },
  buttonMoveItem: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    color: colors.gray50,
    backgroundColor: colors.white,
    borderRadius: "50%",
    position: "absolute",
    right: 0,
    "& svg": {
      fontSize: 24,
    },
  },
  buttonMoveUpItem: {
    top: 0,
  },
  buttonMoveDownItem: {
    bottom: 0,
  },
  gray: {
    color: colors.gray50,
  },
  avatarPrice: {
    minWidth: 42,
    maxWidth: 42,
    minHeight: 42,
    maxHeight: 42,
    backgroundColor: colors.grayLight,
    borderRadius: "50%",
    overflow: "hidden",
    objectFit: "cover",
    marginRight: 12,
  },
  bgGreen: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  bgRed: {
    backgroundColor: colors.red,
    color: colors.white,
  },
  buttonSourceLogoBlack: {
    ...Commons.defaultButton,
    flex: 1,
    backgroundColor: colors.white,
    boxShadow: colors.shadow,
    borderRadius: 12,
    margin: 18,
    textDecoration: "none",
    "& p": {
      color: colors.themeColor,
    },
    [Commons.smallQuery]: {
      margin: "18px 6px",
    },
  },
  logoSource: {
    width: "100%",
  },
  labelLogo: {
    textAlign: "center",
    margin: 0,
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: 18,
  },
  logoWhite: {
    backgroundColor: colors.black,
    "& p": {
      color: colors.white,
    },
  },

  // ------- Product form --------
  rowImages: {
    ...Commons.flexRow,
    marginBottom: 24,
    flexWrap: "wrap",
    gap: 18,
  },
  uploadImgButton: {
    width: 120,
    height: 150,
    borderRadius: 12,
    overflow: "hidden",
    position: "relative",
    "& input": {
      opacity: 0,
      position: "absolute",
      zIndex: -1,
    },
    "& button": {
      ...Commons.defaultButton,
      ...Commons.flexCenter,
      backgroundColor: colors.themeColor,
      width: "100%",
      height: "100%",
      "& svg": {
        color: colors.white,
        fontSize: 42,
      },
    },
  },
  imageProduct: {
    ...Commons.coverBackground,
    width: 120,
    height: 150,
    borderRadius: 12,
    overflow: "hidden",
    position: "relative",
    "& button.delete-btn": {
      ...Commons.centerHorizontal,
      ...Commons.defaultButton,
      ...Commons.flexCenter,
      backgroundColor: colors.red,
      width: 30,
      height: 30,
      borderRadius: 15,
      position: "absolute",
      top: 12,
      "& svg": {
        color: colors.white,
        fontSize: 24,
      },
    },
  },
  rowMoveImgProduct: {
    ...Commons.flexRow,
    position: "absolute",
    bottom: 12,
    width: "100%",
    justifyContent: "space-around",
    "& button": {
      ...Commons.defaultButton,
      ...Commons.flexCenter,
      backgroundColor: colors.white,
      width: 30,
      height: 30,
      borderRadius: 15,
      "& svg": {
        color: colors.black,
        fontSize: 24,
      },
    },
  },
});

export default styles;
