import React, { useState } from "react";
import { CloseRounded } from "@material-ui/icons";
import { useTranslation, Trans } from "react-i18next";
import _ from "lodash";

import * as AvatarImages from "../../assets/images/tutorial";
import {
  setCookie,
  getCookie,
  returnCurrentLink,
} from "../../utils/validations";
import LocalStorage from "../../utils/localStorage";
import { Store } from "../../hooks/main_store";
import Model from "../../hooks/Model";
import AuthService from "../../services/auth";

import * as Routes from "../../utils/routes";
import pageStyles from "./styles";

const Tutorial = () => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);

  const [currentPathname, setCurrentPathname] = useState("");
  const [intervalInfo, setIntervalInfo] = useState(null);
  const [disableTutorial, setDisableTutorial] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [forceShowTutorial, setForceShowTutorial] = useState(false);
  const [acceptedCookies, setAcceptedCookies] = useState(false);
  const [infoTutorial, setInfoTutorial] = useState({
    title: "",
    description: "",
    avatar: "",
  });

  const onTutorial = (forceShow = false) => {
    const pathname = window.location.pathname;

    setShowTutorial(true);
    setInfoTutorial({
      title: "",
      description: "",
      avatar: "",
    });

    if (!showTutorial && forceShow === true) {
      setForceShowTutorial(true);
      clearInterval(intervalInfo);
    }

    if (!acceptedCookies) {
      return setInfoTutorial({
        title: t("components.tutorial.cookiesPolicy"),
        description: (
          <p className={styles.descriptionTutorial}>
            {t("components.tutorial.ourPlatformUseCookies")}{" "}
            <span role="img" aria-label="cookie">
              🍪
            </span>
          </p>
        ),
        avatar: AvatarImages.coding,
        closeCallBack: onClosecookies,
        callBack: () => {
          window.open(
            returnCurrentLink(Routes.RENDER_LEGAL_DOC + "?document=data")
          );
          onClosecookies();
        },
        labelCallback: t("components.tutorial.readMore"),
      });
    }

    switch (pathname) {
      case Routes.DASHBOARD:
        setInfoTutorial({
          title: t("components.tutorial.welcome"),
          description: (
            <p className={styles.descriptionTutorial}>
              <Trans
                i18nKey="components.tutorial.wecolmeMessage"
                components={{ bold: <b /> }}
              />{" "}
              <span role="img" aria-label="party">
                🥳
              </span>
            </p>
          ),
          avatar: AvatarImages.hi,
        });
        break;

      case Routes.ALL_EVENTS:
        setInfoTutorial({
          title: t("components.tutorial.lookAllEvents"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.findEventsFinder")}{" "}
              <span role="img" aria-label="nerd">
                🤓
              </span>
            </p>
          ),
          avatar: AvatarImages.party,
        });
        break;

      case Routes.MY_TRANSACTIONS:
        setInfoTutorial({
          title: t("components.tutorial.ticketsOnHands"),
          description: (
            <p className={styles.descriptionTutorial}>
              <Trans
                i18nKey="components.tutorial.ticketsOnHandsDescription"
                components={{ bold: <b /> }}
              />{" "}
              <span role="img" aria-label="angel">
                😇
              </span>
            </p>
          ),
          avatar: AvatarImages.secret,
        });
        break;

      case Routes.DETAIL_TICKETS:
        setInfoTutorial({
          title: t("components.tutorial.thereAreTickets"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.thereAreTicketsDescription")}{" "}
              <span role="img" aria-label="happy">
                😊
              </span>
            </p>
          ),
          avatar: AvatarImages.starEyes,
        });
        break;

      case Routes.EVENT_FORM:
        setInfoTutorial({
          title: t("components.tutorial.hereBeginAll"),
          description: (
            <p className={styles.descriptionTutorial}>
              <Trans
                i18nKey="components.tutorial.hereBeginAllDescription"
                components={{ bold: <b /> }}
              />{" "}
              <span role="img" aria-label="stars">
                🤩
              </span>
            </p>
          ),
          avatar: AvatarImages.headBlow,
        });
        break;

      case Routes.ADMIN:
        setInfoTutorial({
          title: t("components.tutorial.haveAllPower"),
          description: (
            <p className={styles.descriptionTutorial}>
              <Trans
                i18nKey="components.tutorial.haveAllPowerDescription"
                components={{ bold: <b /> }}
              />{" "}
              <span role="img" aria-label="best">
                😎
              </span>
            </p>
          ),
          avatar: AvatarImages.winkTongue,
        });
        break;

      case Routes.VALIDATE_TICKET:
        setInfoTutorial({
          title: t("components.tutorial.timeValidateTickets"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.timeValidateTicketsDescription")}
            </p>
          ),
          avatar: AvatarImages.hug,
        });
        break;

      case Routes.TEAM:
        setInfoTutorial({
          title: t("components.tutorial.buildTeam"),
          description: (
            <p className={styles.descriptionTutorial}>
              <Trans
                i18nKey="components.tutorial.buildTeamDescription"
                components={{ bold: <b /> }}
              />{" "}
              <span role="img" aria-label="brofist">
                🤜🤛
              </span>
            </p>
          ),
          avatar: AvatarImages.brofist,
        });
        break;

      case Routes.SALES:
        setInfoTutorial({
          title: t("components.tutorial.dataOnHand"),
          description: (
            <p className={styles.descriptionTutorial}>
              <Trans
                i18nKey="components.tutorial.dataOnHandDescription"
                components={{ bold: <b /> }}
              />{" "}
              <span role="img" aria-label="work">
                🧐
              </span>
            </p>
          ),
          avatar: AvatarImages.brofist,
        });
        break;

      case Routes.PROFILE:
        setInfoTutorial({
          title: t("components.tutorial.perzonalizeProfile"),
          description: (
            <p className={styles.descriptionTutorial}>
              <Trans
                i18nKey="components.tutorial.perzonalizeProfileDescription"
                components={{ bold: <b /> }}
              />{" "}
              <b>Partner</b>{" "}
              <span role="img" aria-label="tounge">
                😝
              </span>
            </p>
          ),
          avatar: AvatarImages.wink,
        });
        break;

      case Routes.CONTACT_PUBLISH:
        setInfoTutorial({
          title: t("components.tutorial.wantBePartner"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.wantBePartnerDescription")}{" "}
              <span role="img" aria-label="pray">
                🙏
              </span>
            </p>
          ),
          avatar: AvatarImages.pray,
        });
        break;

      case Routes.EVENTS:
      case Routes.PAYMENT_GATEWAY:
        setInfoTutorial({
          title: t("components.tutorial.thisEventWaits"),
          description: (
            <p className={styles.descriptionTutorial}>
              <Trans
                i18nKey="components.tutorial.thisEventWaitsDescription"
                components={{ bold: <b /> }}
              />{" "}
              <span role="img" aria-label="cowboy">
                🤠
              </span>
            </p>
          ),
          avatar: AvatarImages.show,
        });
        break;

      case Routes.VIDEO_TUTORIAL:
        setInfoTutorial({
          title: t("components.tutorial.ourStepStep"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.ourStepStepDescription")}{" "}
              <span role="img" aria-label="facefake">
                🥸
              </span>
            </p>
          ),
          avatar: AvatarImages.callMe,
        });
        break;

      case Routes.FINISH_PAYMENT:
        setInfoTutorial({
          title: t("components.tutorial.finishPayment"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.finishPaymentDescription")}{" "}
              <span role="img" aria-label="smalltongue">
                😋
              </span>
            </p>
          ),
          avatar: AvatarImages.done,
        });
        break;

      case Routes.CONFIRM_PARTNER:
        setInfoTutorial({
          title: t("components.tutorial.bePartCommunity"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.bePartCommunityDescription")}{" "}
              <span role="img" aria-label="cash">
                🤑
              </span>
            </p>
          ),
          avatar: AvatarImages.heartHand,
        });
        break;

      case Routes.DETAIL_MEMBER:
        setInfoTutorial({
          title: t("components.tutorial.contactInfo"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.contactInfoDescription")}{" "}
              <span role="img" aria-label="robot">
                🤖
              </span>
            </p>
          ),
          avatar: AvatarImages.yei,
        });
        break;

      case Routes.PARTNER_PROFILE:
        setInfoTutorial({
          title: t("components.tutorial.partnerInfo"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.partnerInfoDescription")}{" "}
              <span role="img" aria-label="alien">
                👽
              </span>
            </p>
          ),
          avatar: AvatarImages.idea,
        });
        break;

      case Routes.FAQ:
        setInfoTutorial({
          title: t("components.tutorial.faqs"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.faqsDescription")}{" "}
              <span role="img" aria-label="nerd">
                🤓
              </span>
            </p>
          ),
          avatar: AvatarImages.idea,
        });
        break;

      case Routes.INFO_PLUGIN:
        setInfoTutorial({
          title: t("components.tutorial.newPlugin"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.newPluginDescription")}{" "}
              <span role="img" aria-label="party">
                🥳
              </span>
            </p>
          ),
          avatar: AvatarImages.party,
        });
        break;

      case Routes.DETAIL_PRODUCT:
        setInfoTutorial({
          title: t("components.tutorial.buyProducts"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.buyProductsDescription")}{" "}
              <span role="img" aria-label="party">
                🥳
              </span>
            </p>
          ),
          avatar: AvatarImages.party,
        });
        break;

      case Routes.PRODUCT_FORM:
        setInfoTutorial({
          title: t("components.tutorial.createProduct"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.createProductDescription")}{" "}
              <span role="img" aria-label="coding">
                🤓
              </span>
            </p>
          ),
          avatar: AvatarImages.coding,
        });
        break;

      case Routes.SHOPPING_CART:
        setInfoTutorial({
          title: t("components.tutorial.shoppingCart"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.shoppingCartDescription")}{" "}
              <span role="img" aria-label="coding">
                🤓
              </span>
            </p>
          ),
          avatar: AvatarImages.idea,
        });
        break;

      case Routes.SELL_DETAIL:
        setInfoTutorial({
          title: t("components.tutorial.sellInfo"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.sellInfoDescription")}{" "}
              <span role="img" aria-label="coding">
                🤓
              </span>
            </p>
          ),
          avatar: AvatarImages.hi,
        });
        break;

      case Routes.SHOP_REPORTS:
        setInfoTutorial({
          title: t("components.tutorial.superViseSells"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.superViseSellsDescription")}{" "}
              <span role="img" aria-label="coding">
                🤓
              </span>
            </p>
          ),
          avatar: AvatarImages.coding,
        });
        break;

      case Routes.ADMIN_SHOP:
        setInfoTutorial({
          title: t("components.tutorial.superViseSells"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.superViseSellsAdminDescription")}{" "}
              <span role="img" aria-label="coding">
                🤓
              </span>
            </p>
          ),
          avatar: AvatarImages.coding,
        });
        break;

      case Routes.PROGRAM_INFO:
        setInfoTutorial({
          title: t("components.tutorial.partnerProgram"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.partnerProgramDescription")}{" "}
              <span role="img" aria-label="money">
                🤑
              </span>
            </p>
          ),
          avatar: AvatarImages.coding,
        });
        break;

      case Routes.RENDER_LEGAL_DOC:
        setInfoTutorial({
          title: t("components.tutorial.legalDoc"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.legalDocDescription")}{" "}
              <span role="img" aria-label="nerd">
                🤓
              </span>
            </p>
          ),
          avatar: AvatarImages.coding,
        });
        break;

      case Routes.COMPANY_SOCIAL_MEDIA_LINKS:
        setInfoTutorial({
          title: t("components.tutorial.ourLinks"),
          description: (
            <p className={styles.descriptionTutorial}>
              {t("components.tutorial.ourLinksDescription")}{" "}
              <span role="img" aria-label="nerd">
                🤓
              </span>
            </p>
          ),
          avatar: AvatarImages.coding,
        });
        break;

      default:
        if (state.isOnPartner) {
          setInfoTutorial({
            title: t("components.tutorial.infoOurPartner"),
            description: (
              <p className={styles.descriptionTutorial}>
                {t("components.tutorial.infoOurPartnerDescription")}{" "}
                <span role="img" aria-label="alien">
                  👽
                </span>
              </p>
            ),
            avatar: AvatarImages.idea,
          });
        } else if (state.isOnPluginPartner) {
          setInfoTutorial({
            title: t("components.tutorial.eventsOurPartner"),
            description: (
              <p className={styles.descriptionTutorial}>
                {t("components.tutorial.eventsOurPartnerDescription")}{" "}
                <span role="img" aria-label="alien">
                  👽
                </span>
              </p>
            ),
            avatar: AvatarImages.idea,
          });
        } else {
          setInfoTutorial({
            title: t("components.tutorial.tutorialNoFound"),
            description: (
              <p className={styles.descriptionTutorial}>
                {t("components.tutorial.tutorialNoFoundDescription")}{" "}
                <span role="img" aria-label="party">
                  🙏
                </span>
              </p>
            ),
            avatar: AvatarImages.upset,
          });
        }

        break;
    }
  };

  const saveTutorialInProfile = async (selectedTutorial) => {
    try {
      Model.setData("opacity", 0.7);

      await AuthService.saveTutorialInProfile(
        selectedTutorial.replace(/\//, "")
      );
    } catch (e) {
      Model.updateAlerts({
        message: _.get(e, "message", t("commons.unknowError")),
        variant: "error",
      });
    }
  };

  const onCloseTutorial = () => {
    const pathname = window.location.pathname;
    setShowTutorial(false);

    const statusTutorialCookie = getCookie(`tutorial_${pathname}`);
    const statusTutorialStorage = LocalStorage(state.isIncognito).getItem(
      `tutorial_${pathname}`
    );

    if (!statusTutorialCookie) {
      setCookie(`tutorial_${pathname}`, "done");
    }
    if (!statusTutorialStorage) {
      LocalStorage(state.isIncognito).setItem(`tutorial_${pathname}`, "done");
    }
    if (!statusTutorialCookie && !statusTutorialStorage) {
      saveTutorialInProfile(`tutorial_${pathname}`.replace(/\//, ""));
    }

    if (forceShowTutorial) {
      setDisableTutorial(true);
      setTimeout(() => {
        setDisableTutorial(false);
      }, 250);
    }
  };

  const onClosecookies = () => {
    setShowTutorial(false);
    setAcceptedCookies(true);
    setCookie("cookies_accepted", "accepted");
    LocalStorage(state.isIncognito).setItem("cookies_accepted", "accepted");

    if (forceShowTutorial) {
      setDisableTutorial(true);
      setTimeout(() => {
        setDisableTutorial(false);
      }, 250);
    }
  };

  const verifyBeforeShow = async (selectedTutorial) => {
    try {
      const hasSavedTutorial = await AuthService.hasSavedTutorialInProfile(
        selectedTutorial.replace(/\//, "")
      );

      if (hasSavedTutorial) {
        setShowTutorial(false);
        setCookie(selectedTutorial, "done");
        LocalStorage(state.isIncognito).setItem(selectedTutorial, "done");
      } else {
        onTutorial();
      }
    } catch (e) {
      Model.updateAlerts({
        message: _.get(e, "message", t("commons.unknowError")),
        variant: "error",
      });
    }
  };

  React.useEffect(() => {
    const statusCookiesCookie = getCookie(`cookies_accepted`);
    const statusCookiesStorage = LocalStorage(state.isIncognito).getItem(
      "cookies_accepted"
    );
    const statusCookies = statusCookiesCookie || statusCookiesStorage;

    if (statusCookies === "accepted") {
      setAcceptedCookies(true);
    }

    if (!disableTutorial) {
      clearInterval(intervalInfo);
      setIntervalInfo(
        setInterval(() => {
          const pathname = window.location.pathname;
          const statusTutorialCookie = getCookie(`tutorial_${pathname}`);
          const statusTutorialStorage = LocalStorage(state.isIncognito).getItem(
            `tutorial_${pathname}`
          );
          const statusTutorial = statusTutorialCookie || statusTutorialStorage;

          if (statusTutorial !== "done" || statusCookies !== "accepted") {
            if (currentPathname !== pathname) {
              setCurrentPathname(pathname);
              verifyBeforeShow(`tutorial_${pathname}`);
            } else {
              onTutorial();
            }
          } else {
            setShowTutorial(false);
          }
        }, 5000)
      );
    }
    return () => clearInterval(intervalInfo);
    //eslint-disable-next-line
  }, [disableTutorial, acceptedCookies, currentPathname]);

  React.useEffect(() => {
    if (state.isOnPartner || state.isOnPluginPartner) {
      clearInterval(intervalInfo);
      setDisableTutorial(true);
      setTimeout(() => {
        setDisableTutorial(false);
      }, 500);
    }
    //eslint-disable-next-line
  }, [state.isOnPartner, state.isOnPluginPartner]);

  if (disableTutorial || state.isWhiteLabel) {
    return <></>;
  }

  return (
    <>
      {showTutorial ? (
        <div
          className={[
            styles.rowContentTutorial,
            state.withTabs ? styles.spaceWithTabs : "",
          ].join(" ")}
        >
          <div className={styles.infoContentTutorial}>
            <p className={styles.titleTutorial}>{infoTutorial.title}</p>
            {infoTutorial.description}
            {infoTutorial?.labelCallback ? (
              <button
                onClick={infoTutorial?.callBack || onCloseTutorial}
                className={styles.callbackButton}
              >
                {infoTutorial?.labelCallback}
              </button>
            ) : null}
          </div>
          <div className={styles.contentAvatarTutorial}>
            <img
              className={styles.avatarTutorial}
              alt="tutorial"
              src={infoTutorial.avatar}
            />
          </div>
          <button
            className={styles.closeTutorial}
            onClick={infoTutorial?.closeCallBack || onCloseTutorial}
          >
            <CloseRounded />
          </button>
        </div>
      ) : (
        <div
          className={[
            styles.containerSupport,
            state.withTabs ? styles.spaceWithTabs : "",
          ].join(" ")}
          onClick={() => onTutorial(true)}
        >
          {/* <div
            className={[styles.optionHelp, "optionHide"].join(" ")}
            onClick={onTutorial}
          >
            Ver tutorial
          </div>
          <div className={[styles.optionHelp, "optionHide"].join(" ")}>
            Soporte
          </div> */}
          <div className={styles.circleAvatar}>
            <img
              alt="support"
              src={AvatarImages.coding}
              className={styles.imageAvatar}
            />
          </div>
          <div className={styles.labelSupport}>
            D. {t("components.tutorial.helpYou")}
          </div>
        </div>
      )}
    </>
  );
};

export default Tutorial;
