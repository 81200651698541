const styles = {
  flexColumn: {
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    display: "flex",
  },
  flexRow: {
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
  flexCenter: {
    justifyContent: "center",
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
  coverBackground: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  buttonAction: {
    backgroundColor: "#787F87",
    color: "white",
    padding: "6px 24px",
    width: "fit-content",
    fontSize: 12,
    fontWeight: "bold",
    cursor: "pointer",
    border: "1px solid #787F87",
  },
  defaultButton: {
    border: "none",
    outline: "none",
    cursor: "pointer",
    backgroundColor: "transparent",
    "&:active": {
      opacity: 0.5,
    },
  },
  defaultInput: {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
  },
  centerHorizontal: {
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
  },
  centerVertical: {
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
  },
  smallQuery: "@media(max-width: 780px)",
  bigQuery: "@media(min-width: 780px)",
  salesQuery: "@media(max-width: 1280px)",
};

export default styles;
