import React from "react";
import Model from "../../hooks/Model";
import AuthService from "../../services/auth";

const AuthAction = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const oobCodeParam = urlParams.get("oobCode");
  const apiKeyParam = urlParams.get("apiKey");
  const modeParam = urlParams.get("mode");
  const langParam = urlParams.get("lang") || "es";
  const continueUrlParam = urlParams.get("continueUrl");

  const data = {
    apiKey: apiKeyParam,
    actionCode: oobCodeParam,
    lang: langParam,
  };

  const firstLoad = async () => {
    try {
      if (modeParam === "signIn") {
        const response = await AuthService.validateSignInLinkToEmail(data);
        if (response.ok) {
          window.location.href = continueUrlParam + window.location.search;
        } else {
          throw new Error(response?.data?.message);
        }
      }
    } catch (error) {
      setTimeout(() => {
        window.location.href = continueUrlParam;
      }, 3000);
      Model.updateAlerts({
        message: error?.message || String(error),
        variant: "error",
      });
    }
  };

  React.useEffect(() => {
    firstLoad();
    //eslint-disable-next-line
  }, []);

  return <div />;
};

export default AuthAction;
