import { detectIncognito } from "detectincognitojs";
import _ from "lodash";
import moment from "moment";

import LocalStorage, {
  returnSessionStorage as SessionStorage,
} from "../utils/localStorage";
import {
  authService,
  databaseService,
  firestoreService,
  storageService,
} from "./firebase";
import { returnCurrentLink } from "../utils/validations";
import i18next from "../languages/i18n";
import Model from "../hooks/Model";
import * as Routes from "../utils/routes";
import api from "./api";

const currentLang = LocalStorage(false).getItem("defaultLanguage");

const currentUid = async () => {
  try {
    return authService.currentUser()?.uid;
  } catch (firebaseError) {
    throw Error(firebaseError.message, firebaseError);
  }
};

const currentUser = () => {
  // if (authService.currentUser()) {
  //   authService.currentUser().reload().then(() => {}).catch(() => {})
  // }
  return authService.currentUser();
};

const getMe = async (uid, avoidLoading = false, forceGetInfo = false) => {
  try {
    const { isPrivate } = await detectIncognito();
    let localUserInfo = SessionStorage(isPrivate).getItem("userInfo");
    let localPartnerInfo = SessionStorage(isPrivate).getItem("partnerInfo");
    let responseSecret = {};
    let response = {};
    let userExists = false;
    let responsePartner = {};
    let responsePromoterCode = {};

    Model.setData("defaultLanguage", currentLang || null);

    if (currentUser?.()?.reload) {
      await currentUser().reload();
    }

    if (!avoidLoading && !localUserInfo) {
      Model.setData("loading", true);
      Model.setData("superLoading", true);
    }

    if (!localUserInfo || forceGetInfo) {
      const getUserInfo = await firestoreService.getDoc("users", uid);
      response = getUserInfo.data();
      userExists = getUserInfo.exists();
    } else {
      response = JSON.parse(localUserInfo);
      userExists = true;
    }

    if (!localPartnerInfo || forceGetInfo) {
      responsePartner = (await firestoreService.getDoc("partners", uid)).data();
    } else {
      responsePartner = JSON.parse(localPartnerInfo);
    }

    const responseReferStats = await api.post("/admin/getMyReferStats");

    // if (responsePartner?.verified) {
    responseSecret = await api.post("/admin/getMySecret");
    // }

    if (responsePartner?.promoterCode) {
      responsePromoterCode = await new Promise((resolve) => {
        databaseService
          .getDatabase(`/promoterCodes/${responsePartner?.promoterCode}`)
          .then((data) => resolve(data.val()))
          .catch(() => resolve({}));
      });
    }

    if (userExists) {
      const allUserData = {
        ...response,
        isPartner: responsePartner?.status === "active",
        isPartnerVerified: responsePartner?.verified,
        availableShop: responsePartner?.availableShop,
        promoterCode: responsePromoterCode,
        secret:
          responseSecret.ok && responseSecret.data ? responseSecret.data : null,
        referStats: (responseReferStats.ok && responseReferStats.data) || {},
      };
      Model.updateUserInfo(allUserData);
      Model.setData("teamShop", responsePartner?.team || []);
      Model.setData("bookmarks", response?.bookmarks || []);
      Model.setData("wishProducts", response?.wishProducts || []);
      Model.setData("shoppingCart", response?.shoppingCart || []);
      Model.setData("defaultLanguage", response?.defaultLanguage || null);
      Model.setData("defaultCurrency", response?.defaultCurrency || null);

      SessionStorage(isPrivate).setItem(
        "userInfo",
        JSON.stringify(allUserData)
      );
      SessionStorage(isPrivate).setItem(
        "partnerInfo",
        JSON.stringify(responsePartner)
      );

      if (response?.terms !== "accepted") {
        Model.setData("dialog", {
          open: true,
          title: i18next.t("services.auth.hiWelcome"),
          text: i18next.t("services.auth.weKnowYouFirst"),
          txtLeft: i18next.t("services.auth.goToDocument"),
          fnLeft: () => {
            window
              .open(
                returnCurrentLink(Routes.RENDER_LEGAL_DOC + "?document=terms"),
                "_blank"
              )
              .focus();
          },
          txtRight: i18next.t("services.auth.yesConfirm"),
          fnRight: () => {
            Model.setData("dialog", { open: false });
            Model.setData("loading", true);
            firestoreService
              .updateDoc("users", uid, { terms: "accepted" })
              .then(() => {
                Model.setData("loading", false);
                Model.updateAlerts({
                  message: i18next.t("services.auth.thanksWelcome"),
                  variant: "success",
                });
              })
              .catch(() => Model.setData("loading", false));
          },
          disableBackdropClick: true,
        });
      }
    } else if (uid && currentUser().email) {
      await firestoreService.setDoc("users", uid, {
        name: "",
        email: currentUser().email,
        terms: "pending",
      });
      if (window?.location?.reload) {
        window.location.reload();
      }
    }
  } catch (e) {
    console.log(
      `${i18next.t("services.auth.errorGetInfo")} ', ${_.get(
        e,
        "message",
        i18next.t("commons.unknowError")
      )}`
    );
  } finally {
    Model.setData("superLoading", false);
    Model.setData("loading", false);
    Model.setData("opacity", 0.7);
  }
};

const emailFirebaseLogin = (email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await authService.signInWithEmailAndPassword(
        email,
        password
      );
      await getMe(response.user.uid);
      Model.setData("uid", response.user.uid);
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

const emailFirebaseRegister = (email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await authService.createUserWithEmailAndPassword(
        email,
        password
      );
      await firestoreService.setDoc("users", response.user.uid, {
        email,
        bookmarks: [],
        terms: "accepted",
      });
      await getMe(response.user.uid);
      Model.setData("uid", response.user.uid);
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

const signOut = async (callback = () => {}, beforeState = {}) => {
  try {
    Model.setData("opacity", 1);
    Model.setData("loading", true);

    const isWhiteLabel = !!beforeState?.isWhiteLabel;
    const infoPartner = beforeState?.infoPartner || {};
    const eventsPartnerPlugin = beforeState?.eventsPartnerPlugin || [];

    await authService.signOut();
    Model.resetData();
    Model.setData("uid", "");
    Model.setData("isWhiteLabel", isWhiteLabel);
    Model.setData("infoPartner", infoPartner);
    Model.setData("eventsPartnerPlugin", eventsPartnerPlugin);
    LocalStorage(false).clear();
    SessionStorage(false).clear();
    callback();
  } catch (e) {
    Model.updateAlerts({
      message: `${i18next.t("services.auth.errorSignOut")}: ${_.get(
        e,
        "message",
        i18next.t("services.auth.unknowError")
      )}`,
      variant: "error",
    });
  } finally {
    Model.setData("loading", false);
  }
};

const sendEmailVerification = async () => {
  try {
    // Model.setData('loading', true)
    await authService.sendEmailVerification();
    // Model.updateAlerts({
    //   message: 'Correo de verificación enviado',
    //   variant: 'done'
    // })
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const updateMe = async (uid, data, avoidLoading = false) => {
  try {
    if (!avoidLoading) {
      Model.setData("loading", true);
    }
    await firestoreService.updateDoc("users", uid, data);
    Model.updateUserInfo(data);
    // await getMe(uid, avoidLoading);
  } catch (e) {
    throw _.get(e, "message", e);
  } finally {
    Model.setData("loading", false);
  }
};

const reauthenticate = async (email, password) => {
  try {
    Model.setData("loading", true);
    await authService.reauthenticate(email, password);
    if (authService.currentUser()) {
      await authService.currentUser().reload();
    }
  } catch (e) {
    throw _.get(e, "message", e);
  } finally {
    Model.setData("loading", false);
  }
};

const updateEmail = async (email) => {
  try {
    await authService.updateEmail(email);
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const updatePassword = async (password) => {
  try {
    await authService.updatePassword(password);
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const updatePartnerImages = async (uid, file, label) => {
  try {
    await storageService.uploadImage(`users/${uid}/${label}.png`, file);
    const referenceFile = await storageService.getUrlImage(
      `users/${uid}/${label}.png`
    );
    return referenceFile;
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const updateProfileImage = async (uid, file) => {
  try {
    await storageService.uploadImage(`users/${uid}/profile.png`, file);
    const referenceFile = await storageService.getUrlImage(
      `users/${uid}/profile.png`
    );
    return referenceFile;
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const updateBookmarks = async (uid, data, increase = 0) => {
  try {
    await firestoreService.updateBookmarks(uid, data);
    await api.post("/events/updateInterested", {
      uidEvent: data,
      data: increase,
    });
    await getMe(uid, true);
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const sendPasswordResetEmail = async (email) => {
  try {
    await authService.sendPasswordResetEmail(email);
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const setStateOAuth = async (data) => {
  try {
    Model.setData("loading", true);
    await api.post("/admin/setStateOAuth", data);
  } catch (e) {
    throw _.get(e, "message", e);
  } finally {
    Model.setData("loading", false);
  }
};

const sendSignInLinkToEmail = (data) =>
  api.post("/emails/sendAuthLinkEmail", data);

const validateSignInLinkToEmail = (data) =>
  api.post("/emails/validateSignInLinkToEmail", data);

const hasSavedTutorialInProfile = async (selectedTutorial) => {
  try {
    const userUid = await currentUid();
    if (userUid) {
      return (
        await databaseService.getDatabase(
          `tutorial/${userUid}/${selectedTutorial}`
        )
      ).val();
    }
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const saveTutorialInProfile = async (selectedTutorial) => {
  try {
    const userUid = await currentUid();
    if (userUid) {
      await databaseService.setDatabase(
        `tutorial/${userUid}/${selectedTutorial}`,
        { accepted: moment().format("x") }
      );
    }
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

// const isAdmin = (uid) => {
//   return firebase.database().ref('admins').child(uid).once('value')
// }

// const sendNotificationToUser = (uid, uidto, type, uidto2) => {
//   const body = {uid, uidto, type, uidto2}
//   return api.post('/pushNotifications', body)
// }

// const createUser = (body) => {
//   return api.post('/users/createUser', body)
// }

// const editUser = (body) => {
//   return api.post('/users/editUser', body)
// }

// const chargeCancelPck = (body) => {
//   return api.post('/payment/chargeCancelPck', body)
// }

// const confirmPayoutBank = (body) => {
//   return api.post('/payment/confirmPayoutBank', body)
// }

// const getOrderPaypal = (id) => {
//   return api.post('/payment/getOrderPaypal', {id})
// }

// const postSettings = (body) => {
//   return api.post('/users/settings', body)
// }

export default {
  currentUid,
  currentUser,
  emailFirebaseLogin,
  emailFirebaseRegister,
  getMe,
  signOut,
  sendEmailVerification,
  updateMe,
  reauthenticate,
  updateEmail,
  updatePassword,
  updateProfileImage,
  updateBookmarks,
  sendPasswordResetEmail,
  setStateOAuth,
  updatePartnerImages,
  sendSignInLinkToEmail,
  validateSignInLinkToEmail,
  saveTutorialInProfile,
  hasSavedTutorialInProfile,
  // sendNotificationToUser,
  // isAdmin,
  // createUser,
  // editUser,
  // chargeCancelPck,
  // confirmPayoutBank,
  // getOrderPaypal,
  // postSettings
};
