import { detectIncognito } from "detectincognitojs";
import moment from "moment";
import _ from "lodash";

import { returnSessionStorage as SessionStorage } from "../utils/localStorage";
import { analyticsService, storageService, databaseService } from "./firebase";
import i18next from "../languages/i18n";
import Model from "../hooks/Model";
import api from "./api";

const getEvents = async () => {
  try {
    const { isPrivate } = await detectIncognito();
    let localEvents = SessionStorage(isPrivate).getItem("events");
    let localEventsDate = SessionStorage(isPrivate).getItem("events_updatedAt");

    if (
      localEvents &&
      moment().diff(moment(localEventsDate, "x"), "minutes") < 10 &&
      process.env.NODE_ENV !== "development"
    ) {
      Model.setData(
        "events",
        JSON.parse(localEvents)?.published?.sort?.((a, b) => a.date - b.date) ||
          []
      );
      Model.setData(
        "draftEvents",
        JSON.parse(localEvents)?.draft?.sort?.((a, b) => a.date - b.date) || []
      );
    } else {
      const response = await api.get("/events/getEvents");
      if (response.ok) {
        if (response?.data?.published) {
          Model.setData(
            "events",
            response?.data?.published?.sort?.((a, b) => a.date - b.date) || []
          );
        }
        if (response?.data?.draft) {
          Model.setData(
            "draftEvents",
            response?.data?.draft?.sort?.((a, b) => a.date - b.date) || []
          );
        }
        SessionStorage(isPrivate).setItem(
          "events",
          JSON.stringify(response.data)
        );
      } else {
        throw new Error(i18next.t("services.events.errorGetEvents"));
      }
    }
  } catch (e) {
    throw e;
  }
};

const searchEvent = async (text = "", allEvents = []) => {
  try {
    Model.setData("searchedEvents", []);
    const events = allEvents
      .filter((event) => {
        return event.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
      })
      .sort((a, b) => b.date - a.date);

    Model.setData("searchedEvents", events);
  } catch (e) {
    throw e;
  }
};

const getMyTickets = async () => {
  try {
    // Model.setData("myTickets", []);
    const { isPrivate } = await detectIncognito();
    const response = await api.get("/tickets/getMyTickets");

    SessionStorage(isPrivate).setItem(
      "myTickets",
      JSON.stringify(response?.data?.filter ? response.data : [])
    );
    Model.setData("myTickets", response?.data?.filter ? response.data : []);
  } catch (e) {
    throw e;
  }
};

const getEvent = async (eventUid) => {
  try {
    const response = await api.post("/events/getEvent", { eventUid });
    if (response.ok && response.status === 200) {
      return {
        ...response.data,
        uid: eventUid,
      };
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const bookAticket = async (data) => {
  try {
    const response = await api.post("/tickets/bookAticket", data);
    if (response.ok && response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const bookAticketWithEmail = async (data) => {
  try {
    const response = await api.post("/tickets/bookAticketWithEmail", data);
    if (response.ok && response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const getPaymentStatus = async (data) => {
  try {
    const response = await api.post("/tickets/getPaymentStatus", data);
    if (response.ok && response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const getEventsContacts = async (data) => {
  try {
    const response = await api.post("/events/getEventsContacts", data);
    if (response.ok && response.status === 200) {
      return response.data || [];
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const getEventsPartner = async () => {
  try {
    // Owner
    const events = [];
    const { isPrivate } = await detectIncognito();
    const response = await api.get("/events/getEventsPartner");
    if (response.ok && response.status === 200) {
      if (response?.data?.length > 0) {
        response.data.forEach((data) => {
          if (data) {
            events.push({
              ...data,
              contacts: null,
            });
          }
        });
      }
    } else {
      throw new Error(response.data);
    }
    // Editors
    Model.setData(
      "eventsPartner",
      events.sort((a, b) => b.date - a.date)
    );
    SessionStorage(isPrivate).setItem(
      "eventsPartner",
      JSON.stringify(events.sort((a, b) => b.date - a.date))
    );
  } catch (e) {
    Model.setData("eventsPartner", []);
    throw e;
  }
};

const logEvent = async (name = "") => {
  try {
    if (
      name &&
      typeof name === "string" &&
      process.env.NODE_ENV === "production"
    ) {
      await analyticsService.logEvent(
        `event_visited_${name.toLowerCase().replace(/\s/g, "_")}`
      );
    }
  } catch (e) {
    console.log(i18next.t("services.events.errorAddEvent") + ": ", String(e));
  }
};

const createEvent = async (data) => {
  try {
    const response = await api.post("/admin/createEvent", data);
    if (response.ok && response.status === 200) {
      return response.data || [];
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const updateEventImages = async (
  uid,
  eventUid,
  banner,
  logo,
  imageDescription
) => {
  try {
    let referenceBanner = "";
    let referenceLogo = "";
    let referenceDescription = "";

    if (banner) {
      await storageService.uploadImage(
        `events/${uid}/${eventUid}/poster.png`,
        banner
      );
      referenceBanner = await storageService.getUrlImage(
        `events/${uid}/${eventUid}/poster.png`
      );
    }

    if (logo) {
      await storageService.uploadImage(
        `events/${uid}/${eventUid}/logo.png`,
        logo
      );
      referenceLogo = await storageService.getUrlImage(
        `events/${uid}/${eventUid}/logo.png`
      );
    }

    if (imageDescription) {
      await storageService.uploadImage(
        `events/${uid}/${eventUid}/imageDescription.png`,
        imageDescription
      );
      referenceDescription = await storageService.getUrlImage(
        `events/${uid}/${eventUid}/imageDescription.png`
      );
    }
    return [referenceBanner, referenceLogo, referenceDescription];
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const updateArtistImage = async ({ uid, eventUid, artist, image }) => {
  try {
    let referenceImage = "";
    const url = `events/${uid}/${eventUid}/artists/${artist}-${moment().format(
      "x"
    )}.png`;
    if (image) {
      await storageService.uploadImage(url, image);
      referenceImage = await storageService.getUrlImage(url);
    }
    return referenceImage;
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const updateEvent = async (data) => {
  try {
    const response = await api.post("/admin/updateEvent", data);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const postTransferTicket = async (data) => {
  try {
    const response = await api.post("/tickets/postTransferTicket", data);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const getSettingsEvents = async () => {
  try {
    const response = await api.get("/events/getSettingsEvents");
    if (response.ok && response.status === 200) {
      return response?.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const getStatsMemberUser = async (eventUid) => {
  try {
    const response = await api.post("/events/getStatsMemberUser", { eventUid });
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const getSaleData = async (ticketUid) => {
  try {
    const response = await api.post("/events/getSaleData", { ticketUid });
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const editPromoter = async (dataTicket) => {
  try {
    const response = await api.post("/tickets/editPromoter", dataTicket);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const confirmPromoCode = async (dataPromoCode) => {
  try {
    const response = await api.post("/tickets/confirmPromoCode", dataPromoCode);
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw response.data;
    }
  } catch (e) {
    throw e;
  }
};

const confirmPromoCodeWithEmail = async (dataPromoCode) => {
  try {
    const response = await api.post(
      "/tickets/confirmPromoCodeWithEmail",
      dataPromoCode
    );
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw response.data;
    }
  } catch (e) {
    throw e;
  }
};

const confirmTransferTicket = async (data) => {
  try {
    const response = await api.post("/tickets/confirmTransferTicket", data);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const cancelTransfer = async (data) => {
  try {
    const response = await api.post("/tickets/cancelTransfer", data);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const getQuestions = async () => {
  try {
    const snap = await databaseService.getDatabase("/faqs");
    if (snap.exists()) {
      return snap.val() || [];
    } else {
      throw new Error(i18next.t("services.events.withoutQuestions"));
    }
  } catch (e) {
    throw e;
  }
};

const sellMyTickets = async (data) => {
  try {
    const response = await api.post("/tickets/sellMyTickets", data);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const cancelSellMyTicket = async (data) => {
  try {
    const response = await api.post("/tickets/cancelSellMyTicket", data);
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const getSellings = async (data) => {
  try {
    const response = await api.post("/tickets/getSellings", data);
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const confirmSell = async (data) => {
  try {
    const response = await api.post("/tickets/confirmSell", data);
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const returnIdsPreferencesTickets = async (data) => {
  try {
    const response = await api.post(
      "/tickets/returnIdsPreferencesTickets",
      data
    );
    if (response.ok && response.status === 200) {
      return response.data || [];
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const getEventTypes = async () => {
  try {
    const snap = await databaseService.getDatabase("/eventTypes");
    if (snap.exists()) {
      return snap.val() || [];
    } else {
      throw new Error(i18next.t("services.events.withoutEventTypes"));
    }
  } catch (e) {
    throw e;
  }
};

const updateRefreshCode = async (ticket) => {
  try {
    const response = await api.post("/tickets/updateRefreshCode", { ticket });
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const verifyNequiTransaction = async (id) => {
  try {
    const response = await api.post("/tickets/verifyNequiTransaction", { id });
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const cancelNequiTransaction = async (id) => {
  try {
    const response = await api.post("/tickets/cancelNequiTransaction", { id });
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

export default {
  getEvents,
  searchEvent,
  getMyTickets,
  getEvent,
  bookAticket,
  getPaymentStatus,
  getEventsContacts,
  getEventsPartner,
  logEvent,
  createEvent,
  updateEventImages,
  updateEvent,
  postTransferTicket,
  getSettingsEvents,
  getStatsMemberUser,
  getSaleData,
  editPromoter,
  updateArtistImage,
  confirmPromoCode,
  confirmTransferTicket,
  cancelTransfer,
  getQuestions,
  sellMyTickets,
  cancelSellMyTicket,
  getSellings,
  confirmSell,
  returnIdsPreferencesTickets,
  getEventTypes,
  confirmPromoCodeWithEmail,
  bookAticketWithEmail,
  updateRefreshCode,
  verifyNequiTransaction,
  cancelNequiTransaction,
};
