/* eslint-disable */
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import { CheckCircle } from "@material-ui/icons";
import EventsService from "../../services/events";
import { Store } from "../../hooks/main_store";
import pageStyles from "./styles";
import * as Routes from "../../utils/routes";
import Model from "../../hooks/Model";

const NequiLoading = ({ history }) => {
  const styles = pageStyles();
  const { t } = useTranslation();

  const [show, setShow] = React.useState(false);
  const [showDone, setShowDone] = React.useState(false);
  const [callback, setCallback] = React.useState(null);
  const { state } = React.useContext(Store);

  const runDoneTransition = (status) => {
    Model.setData("nequiTransactionId", "");

    if (status === "35") {
      setShowDone(true);

      setTimeout(() => {
        history.push(
          `${Routes.FINISH_PAYMENT}?nequi_transaction=${state.nequiTransactionId}`
        );
      }, 3000);
    } else {
      setTimeout(() => {
        history.push(
          `${Routes.FINISH_PAYMENT}?nequi_transaction=${state.nequiTransactionId}`
        );
      }, 500);
    }
  };

  const verifyTransaction = async () => {
    try {
      const response = await EventsService.verifyNequiTransaction(
        state.nequiTransactionId
      );

      if (response.status !== "33") {
        runDoneTransition(response.status);
      }
    } catch (e) {
      console.log("Nequi error: ", e?.message || String(e));
    }
  };

  const onCancelEvent = async () => {
    try {
      Model.setData("dialog", { open: false });
      Model.setData("loading", true);
      const response = await EventsService.cancelNequiTransaction(
        state.nequiTransactionId
      );

      if (response.id === state.nequiTransactionId) {
        history.push(
          `${Routes.FINISH_PAYMENT}?nequi_transaction=${state.nequiTransactionId}`
        );
      } else {
        throw response;
      }
    } catch (e) {
      Model.updateAlerts({
        message: e?.message || String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onCancel = () => {
    Model.setData("dialog", {
      open: true,
      title: "Antes de continuar ...",
      text: "¿Estas de acuerdo en cancelar esta transacción? Perderás tu reserva y esta acción no se podrá revertir.",
      txtLeft: t("commons.cancel"),
      fnLeft: () => {
        Model.setData("dialog", { open: false });
      },
      txtRight: t("commons.yes"),
      fnRight: onCancelEvent,
      disableBackdropClick: true,
    });
  };

  React.useEffect(() => {
    if (show) {
      setCallback(setInterval(verifyTransaction, 10000));
    } else {
      clearInterval(callback);
    }
    return () => clearInterval(callback);
  }, [show]);

  React.useEffect(() => {
    if (state.nequiTransactionId) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [state.nequiTransactionId]);

  return (show || showDone) && !state.loading && !state.superLoading ? (
    <div className={styles.modal}>
      <div className={styles.card}>
        <div className={styles.spinner}>
          {showDone ? (
            <CheckCircle fontSize="large" />
          ) : (
            <CircularProgress size={42} />
          )}
        </div>
        <div className={styles.info}>
          <p className={styles.title}>
            {showDone
              ? "¡Hecho! Aprobación completa"
              : "Estamos esperando a Nequi"}
          </p>
          <p className={styles.description}>
            {showDone
              ? "Ya hemos confirmado tu compra, estamos terminando los últimos detalles de tu compra para redirigirte a tu siguiente paso"
              : "Ingresa a Nequi, ve a notificaciones y confirma tu compra. Estaremos esperando la confirmación de la transacción. No cierres esta ventana"}
          </p>
          {!showDone && (
            <button className={styles.cancelButton} onClick={onCancel}>
              ¿Ya no deseas continuar? Cancela aquí
            </button>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default NequiLoading;
