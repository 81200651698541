import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  modal: {
    ...Commons.flexCenter,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: `${colors.themeColor}90`,
    zIndex: 2,
  },
  card: {
    ...Commons.flexRow,
    backgroundColor: colors.white,
    padding: 24,
    boxShadow: colors.shadow2,
    width: "75%",
    boxSizing: "content-box",
    maxWidth: 540,
    borderRadius: 6,
    gap: 18,
    [Commons.smallQuery]: {
      flexDirection: "column",
    },
  },
  spinner: {
    "& svg": {
      color: colors.themeColor,
    },
  },
  info: {
    flex: 1,
  },
  title: {
    margin: 0,
    fontWeight: 600,
    fontSize: 30,
    color: colors.themeColor,
    [Commons.smallQuery]: {
      fontSize: 18,
    },
  },
  description: {
    margin: "12px 0 0 0",
    fontSize: 18,
    color: colors.black,
    [Commons.smallQuery]: {
      fontSize: 12,
    },
  },
  cancelButton: {
    ...Commons.defaultButton,
    color: colors.red,
    fontSize: 14,
    padding: "6px 0",
    fontWeight: "bold",
  },
});

export default useStyles;
