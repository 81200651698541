import React from "react";
import ReactDOM from "react-dom";

// import { returnSessionStorage as SessionStorage } from "./utils/localStorage";
import * as serviceWorker from "./serviceWorker";
// import i18next from "./languages/i18n";
import "./languages/i18n";
import App from "./App";
import "./index.css";

// import { saveAs } from "file-saver";
// import * as XLSX from "xlsx";
// import moment from "moment";
// import allInfo from "./juntos.json";

// const codeVerify = moment().format("x").slice(0, 5);
// const isTest = window?.location?.host?.includes?.("test.");

const initialFunction = () => {
  ReactDOM.render(<App />, document.getElementById("root"));

  // const isAllowed = SessionStorage(false).getItem("allow_test_user");

  // if (isTest && isAllowed !== "allowed") {
  //   const code = prompt(i18next.t("system.beforeTest"), "...");

  //   if (code === codeVerify) {
  //     SessionStorage(false).setItem("allow_test_user", "allowed");
  //     ReactDOM.render(<App />, document.getElementById("root"));
  //   } else if (code != null) {
  //     alert(i18next.t("system.errorTestCode"));
  //     initialFunction();
  //   }
  // } else {
  //   ReactDOM.render(<App />, document.getElementById("root"));
  // }
};

initialFunction();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// const returnDataExport = () => {
//   let dataExport = [
//     [
//       "Usuario",
//       "Nombre",
//       "Cargo",
//       "Super poder",
//       "1ra pregunta",
//       "2da pregunta",
//     ],
//   ];
//   Object.keys(allInfo).forEach((user) => {
//     dataExport.push([
//       user,
//       allInfo[user].name,
//       allInfo[user].role,
//       allInfo[user].selectedOption,
//       allInfo[user].firstQuestion,
//       allInfo[user].secondQuestion,
//     ]);
//   });
//   return dataExport;
// };

// const s2ab = (s) => {
//   var buf = new ArrayBuffer(s.length);
//   var view = new Uint8Array(buf);
//   for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
//   return buf;
// };

// const exportData = () => {
//   const wb = XLSX.utils.book_new();
//   const secondNow = moment().format("x");
//   wb.Props = {
//     Title: "juntos",
//     Subject: "kimberly",
//     Author: "innventa",
//     CreatedDate: new Date(),
//   };
//   wb.SheetNames.push("users");
//   wb.Sheets["users"] = XLSX.utils.aoa_to_sheet(returnDataExport());
//   const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
//   saveAs(
//     new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
//     `users_${secondNow}.xlsx`
//   );
// };

// exportData();
