import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  bannerInfo: {
    ...Commons.flexRow,
    width: "100%",
    height: 720,
    marginTop: -90,
    backgroundColor: colors.black,
    paddingTop: 0,
    zIndex: 0,
    [Commons.smallQuery]: {
      paddingTop: 78,
      marginTop: -78,
      margin: "0px auto",
      height: 300,
    },
  },
  imageBannerInfo: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: 0,
    top: 0,
    left: 0,
    position: "absolute",
  },
  filterImageBannerInfo: {
    width: "100%",
    height: "100%",
    zIndex: 0,
    top: 0,
    left: 0,
    position: "absolute",
    backgroundColor: "rgba(0 ,0, 0, 0.4)",
  },
  textBannerInfo: {
    flex: 1,
    textAlign: "center",
    position: "relative",
    zIndex: 1,
    [Commons.smallQuery]: {
      marginLeft: 0,
    },
  },
  introText: {
    letterSpacing: "-3px",
    fontWeight: "700",
    fontSize: 66,
    margin: "12px auto",
    color: colors.white,
    textShadow: colors.textShadow,
    whiteSpace: "break-spaces",
    maxWidth: 1200,
    height: 240,
    "& em": {
      fontStyle: "normal",
      padding: "0 6px",
      textShadow: `0px 0px 6px ${colors.themeColor}`,
    },
    [Commons.smallQuery]: {
      height: 90,
      fontSize: 24,
      letterSpacing: 0,
    },
  },
  subIntro: {
    fontSize: 24,
    color: colors.white,
    textShadow: "0px 4px 4px #000000",
    margin: "0 auto",
    marginBottom: 18,
    maxWidth: "90%",
    fontWeight: "500",
    [Commons.smallQuery]: {
      fontSize: 18,
    },
  },
  ctaBannerInfo: {
    ...Commons.defaultButton,
    color: colors.white,
    backgroundColor: colors.themeColor,
    padding: "12px 30px",
    fontSize: 18,
    fontWeight: "bold",
    borderRadius: 12,
    boxShadow: colors.shadow,
    textDecoration: "none",
    [Commons.smallQuery]: {
      padding: 12,
      fontSize: 14,
    },
  },
  shadowBanner: {
    width: "100%",
    height: 30,
    background: "linear-gradient(180deg, gray 0%, white 100%)",
    position: "absolute",
    top: "100%",
  },
  rowButtons: {
    ...Commons.flexRow,
    margin: "0 auto",
    width: "fit-content",
    alignItems: "center",
    marginTop: 30,
    [Commons.smallQuery]: {
      marginTop: 18,
    },
  },
  orLabel: {
    color: colors.white,
    margin: "0 12px",
  },
});

export default useStyles;
