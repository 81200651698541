import React from "react";
import {
  Visibility,
  Close,
  CheckCircle,
  ArrowUpward,
  ArrowDownward,
} from "@material-ui/icons";
import { Tooltip, TextField } from "@material-ui/core";
import Autocomplete from "react-google-autocomplete";
import { useTranslation } from "react-i18next";
import moment from "moment";

import {
  sizeImage,
  currencyFormat,
  verifyUrl,
  moveItemsArray,
} from "../../utils/validations";
import ContentEvent from "../../components/ContentEvent";
import BannerEvent from "../../components/BannerEvent";
import EventsService from "../../services/events";
import TypesEvent from "../../components/TypesEvent";
import Avatar from "../../assets/images/avatar.jpeg";
import { Store } from "../../hooks/main_store";
import Input from "../../components/input";
import Model from "../../hooks/Model";
import Layout from "../layout";

import pageStyles from "./styles/eventFormStyles";

const EventForm = ({ history }) => {
  const { t } = useTranslation();
  const { state } = React.useContext(Store);
  const styles = pageStyles();
  const beforeData = history?.location?.state?.draftEvent;

  const toEdit = !!beforeData;
  const steps = [
    {
      label: t("pages.admin.eventForm.personalData"),
      button: t("pages.admin.eventForm.data"),
    },
    {
      label: t("pages.admin.eventForm.contentAndArtists"),
      button: t("pages.admin.eventForm.content"),
    },
    {
      label: t("pages.admin.eventForm.pricesAndTc"),
      button: t("pages.admin.eventForm.prices"),
    },
    {
      label: t("pages.admin.eventForm.configuration"),
      button: t("pages.admin.eventForm.configuration"),
    },
  ];

  const [touchedStep, setTouchedStep] = React.useState(
    new Array(4).fill(false)
  );
  const [selectedStep, setSelectedStep] = React.useState(0);
  const [showPreview, setShowPreview] = React.useState(false);
  const [showStatus, setShowStatus] = React.useState(true);
  const [pendingPhotos, setPendingPhotos] = React.useState({});
  const [selectedType, setSelectedType] = React.useState("");
  const [vc, setVc] = React.useState({
    artists: [],
    date: "",
    dateEnd: "",
    coords: "",
    description: "",
    imageBanner: "",
    imageDescription: "",
    location: "",
    logo: "",
    name: "",
    prices: [],
    private: null,
    hideAddress: false,
    isOlder18: false,
    showPromoters: false,
    enablePromoters: false,
    status: "",
    terms: "",
    newPriceLabel: "",
    newPriceValue: "",
    newUsdPriceValue: "",
    newPriceStock: "",
    newArtistTitle: "",
    newArtistSubtitle: "",
    newArtistLink: "",
    newArtistImage: "",
    imageBannerPreview: "",
    imageDescriptionPreview: "",
    imageLogoPreview: "",
    fbPx: "",
    clientPayTax: false,
    saleWithPhases: false,
    showFbPixel: false,
    acceptOnlinePayments: false,
    acceptUsd:
      beforeData?.acceptUsd ||
      (!!state?.userInfo?.secret?.bitcoin?.walletAddress &&
        state?.userInfo?.secret?.verified) ||
      (!state?.userInfo?.secret?.verified && state.defaultCurrency === "USD"),
    acceptCop: toEdit
      ? !beforeData?.acceptCop && !beforeData?.acceptUsd
        ? true
        : beforeData?.acceptCop
      : (!!state?.userInfo?.secret?.mercadopago &&
          state?.userInfo?.secret?.verified) ||
        (!state?.userInfo?.secret?.verified && state.defaultCurrency === "COP"),
    errors: {},
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const changeState = (name) => (event) => {
    let valueInput = "";
    event.persist();

    switch (name) {
      case "newPriceStock":
        valueInput = event.target.value.replace(".", "");
        break;
      case "date":
      case "dateEnd":
        valueInput = moment(event.target.value, "YYYY-MM-DDThh:mm").format("x");
        break;
      default:
        valueInput = event.target.value;
    }
    changeVc({
      [name]: valueInput,
    });
  };

  const changeAcceptCurrency = (label, value) => {
    const otherLabel = label === "acceptCop" ? "acceptUsd" : "acceptCop";

    if (vc[label] && !vc[otherLabel] && !value) {
      return Model.updateAlerts({
        message: t("pages.admin.eventForm.mustLetOneCoin"),
        variant: "warning",
      });
    }

    changeVc({ [label]: value, prices: [] });
  };

  const changeTypeFilter = (type) => setSelectedType(type);

  const changeStock = (index) => (event) => {
    const newDataPrices = [...vc.prices];
    newDataPrices[index].stock = parseInt(event.target.value);
    event.persist();
    changeVc({
      prices: newDataPrices,
    });
  };

  const returnHasError = (step) => {
    const auxPrices = vc.prices;

    const stepOne = {
      name: !vc.name,
      date: !vc.date,
      imageBanner: beforeData?.uid ? false : !vc.imageBanner,
      selectedType: !selectedType,
    };
    const stepThree = {
      prices: auxPrices.length <= 0,
      terms: !vc.terms,
    };
    const stepFour = {
      private: vc.private === null,
      status: !vc.status,
    };
    const allSteps = {
      name: !vc.name,
      date: !vc.date,
      prices: auxPrices.length <= 0,
      private: vc.private === null,
      terms: !vc.terms,
      status: !vc.status,
      imageBanner: beforeData?.uid ? false : !vc.imageBanner,
      selectedType: !selectedType,
    };

    let newErrors = { errors: {} };

    switch (step) {
      case 0:
        newErrors.errors = stepOne;
        break;
      case 1:
        newErrors.errors = {};
        break;
      case 2:
        newErrors.errors = stepThree;
        break;
      case 3:
        newErrors.errors = stepFour;
        break;
      default:
        newErrors.errors = allSteps;
    }

    return {
      hasErrors: Object.keys(newErrors.errors)
        .map((keyLabel) => !!newErrors.errors[keyLabel])
        .includes(true),
      newErrors,
    };
  };

  const verifyHasErrors = (step) => {
    const { hasErrors, newErrors } = returnHasError(step);

    changeVc(newErrors);

    if (hasErrors) {
      Model.updateAlerts({
        message: t("pages.admin.eventForm.enterAllData"),
        variant: "error",
      });
    }

    return hasErrors;
  };

  const validateForm = (callback = () => {}, forceSave = false) => {
    const auxPrices = vc.prices;

    changeVc({ errors: {} });

    if (
      vc.name &&
      (beforeData?.uid ? true : vc.imageBanner) &&
      vc.date &&
      auxPrices.length > 0 &&
      vc.private !== null &&
      vc.terms &&
      vc.status &&
      selectedType &&
      forceSave
    ) {
      return callback();
    }

    switch (selectedStep) {
      case 0:
        const hasErrorsOne = verifyHasErrors(selectedStep);

        if (
          vc.name &&
          (beforeData?.uid ? true : vc.imageBanner) &&
          vc.date &&
          selectedType &&
          !hasErrorsOne
        ) {
          if (forceSave) {
            callback();
          } else {
            setSelectedStep((ov) => ov + 1);
          }
        }
        break;
      case 1:
        if (forceSave) {
          callback();
        } else {
          setSelectedStep((ov) => ov + 1);
        }
        break;
      case 2:
        const hasErrorsThree = verifyHasErrors(selectedStep);
        if (auxPrices.length > 0 && vc.terms && !hasErrorsThree) {
          if (forceSave) {
            callback();
          } else {
            setSelectedStep((ov) => ov + 1);
          }
        }
        break;
      case 3:
        const hasErrorsAll = verifyHasErrors();
        if (vc.private !== null && vc.status && !hasErrorsAll) {
          const coords = vc.coords.split(",");
          if (
            !vc.coords ||
            (coords.length === 2 && !isNaN(coords[0]) && !isNaN(coords[1]))
          ) {
            callback();
          } else {
            Model.updateAlerts({
              message: t("pages.admin.eventForm.coordsHasFormat"),
              variant: "error",
            });
          }
        }
        break;
      default:
        setSelectedStep(1);
    }
  };

  const popPrice = (index) => {
    const auxItems = [...vc.prices];
    auxItems.splice(index, 1);
    changeVc({ prices: auxItems });
  };

  const movePrice = (index, nextPosition) => {
    const auxItems = moveItemsArray(vc.prices, index, index + nextPosition);
    changeVc({ prices: auxItems });
  };

  const onAddPrice = () => {
    if (vc.acceptCop && vc.newPriceValue < 1000) {
      Model.updateAlerts({
        message: t("pages.admin.eventForm.priceMustMajor"),
        variant: "error",
      });
    } else if (vc.acceptUsd && vc.newUsdPriceValue <= 0) {
      Model.updateAlerts({
        message: t("pages.admin.eventForm.addPriceUsd"),
        variant: "error",
      });
    } else if (
      vc.newPriceLabel &&
      (vc.newPriceValue || vc.newUsdPriceValue) &&
      vc.newPriceStock
    ) {
      const auxItems = [...vc.prices];

      if (
        auxItems.filter((item) => item.label === vc.newPriceLabel).length > 0
      ) {
        return Model.updateAlerts({
          message: t("pages.admin.eventForm.nameMustBeDif"),
          variant: "error",
        });
      }

      auxItems.push({
        label: vc.newPriceLabel,
        price: vc.acceptCop
          ? parseFloat(parseFloat(vc.newPriceValue || 0).toFixed(2))
          : 0,
        usdPrice: vc.acceptUsd
          ? parseFloat(parseFloat(vc.newUsdPriceValue || 0).toFixed(2))
          : 0,
        stock: parseInt(vc.newPriceStock),
      });
      changeVc({
        prices: auxItems,
        newPriceLabel: "",
        newPriceValue: "",
        newUsdPriceValue: "",
        newPriceStock: "",
      });
    } else {
      Model.updateAlerts({
        message: t("pages.admin.eventForm.enterAllDataPrice"),
        variant: "error",
      });
    }
  };

  const popArtist = (index) => {
    const auxItems = [...vc.artists];
    auxItems.splice(index, 1);
    changeVc({ artists: auxItems });
  };

  const moveArtist = (index, nextPosition) => {
    const auxItems = moveItemsArray(vc.artists, index, index + nextPosition);
    changeVc({ artists: auxItems });
  };

  const onAddArtist = () => {
    if (vc.newArtistLink && !verifyUrl(vc.newArtistLink)) {
      Model.updateAlerts({
        message: t("pages.admin.eventForm.linkMustHaveFormat"),
        variant: "error",
      });
    } else if (vc.newArtistTitle) {
      const auxItems = [...vc.artists];
      auxItems.push({
        image: vc.newArtistImage,
        name: vc.newArtistTitle,
        subtitle: vc.newArtistSubtitle,
        link: vc.newArtistLink,
      });
      changeVc({
        artists: auxItems,
        newArtistImage: "",
        newArtistTitle: "",
        newArtistSubtitle: "",
        newArtistLink: "",
      });
    } else {
      Model.updateAlerts({
        message: t("pages.admin.eventForm.enterTitle"),
        variant: "error",
      });
    }
  };

  const isCurrentPhase = (index) => {
    let currentPhase = -1;
    let nextPhase = -1;

    if (vc.prices.length < 2) {
      return "";
    }

    vc.prices.forEach((price, indexPrice) => {
      if (price?.stock > 0) {
        if (currentPhase === -1) {
          currentPhase = indexPrice;
        } else if (nextPhase === -1) {
          nextPhase = indexPrice;
        }
      }
    });

    return index === currentPhase
      ? styles.currentPhasePrice
      : index === nextPhase
      ? styles.nextPhasePrice
      : "";
  };

  const renderPrices = () => {
    return (
      <>
        <div className={styles.contPrices}>
          {vc.prices.length > 0
            ? vc.prices.map((price, index) => (
                <div
                  key={"item-price-" + index}
                  className={[
                    styles.rowInfoPrice,
                    vc.saleWithPhases ? isCurrentPhase(index) : "",
                  ].join(" ")}
                >
                  <p className={styles.textInfoPrice}>{price?.label}</p>
                  {price?.price >= 0 && vc.acceptCop ? (
                    <p className={styles.textInfoPrice}>
                      {currencyFormat(price?.price, "COP", 0)} COP
                    </p>
                  ) : null}
                  {price?.usdPrice >= 0 && vc.acceptUsd ? (
                    <p className={styles.textInfoPrice}>
                      {currencyFormat(price?.usdPrice, "COP", 2)} USD
                    </p>
                  ) : null}

                  {toEdit || vc.typeTemplate === "free" ? (
                    <button
                      className={[
                        styles.buttonInfoPrice,
                        price.stock > 0 ? styles.gray : "",
                      ].join(" ")}
                      onClick={() => {
                        const newDataPrices = [...vc.prices];
                        newDataPrices[index].stock = 0;
                        changeVc({
                          prices: newDataPrices,
                        });
                      }}
                    >
                      {price.stock > 0
                        ? t("pages.admin.eventForm.toEmpty")
                        : t("pages.admin.eventForm.soldOut")}
                    </button>
                  ) : null}

                  {toEdit || vc.typeTemplate === "free" ? (
                    <Input
                      customStyles={styles.inputPriceCreated}
                      placeholder={t("pages.admin.eventForm.availableQuantity")}
                      onChange={changeStock(index)}
                      value={String(parseInt(price.stock))}
                      type="number"
                      min={0}
                    />
                  ) : (
                    <>
                      <p className={styles.textInfoPrice}>
                        {price?.stock} {t("pages.admin.eventForm.available")}
                      </p>
                      {index <= 0 ? null : (
                        <button
                          className={[
                            styles.buttonMoveItem,
                            styles.buttonMoveUpItem,
                          ].join(" ")}
                          onClick={() => movePrice(index, -1)}
                        >
                          <ArrowUpward />
                        </button>
                      )}
                      {index + 1 >= vc.prices.length ? null : (
                        <button
                          className={[
                            styles.buttonMoveItem,
                            styles.buttonMoveDownItem,
                          ].join(" ")}
                          onClick={() => movePrice(index, 1)}
                        >
                          <ArrowDownward />
                        </button>
                      )}
                      <button
                        className={styles.buttonInfoPrice}
                        onClick={() => popPrice(index)}
                      >
                        <Close />
                      </button>
                    </>
                  )}

                  {vc.saleWithPhases ? (
                    isCurrentPhase(index) === styles.currentPhasePrice ? (
                      <div className={styles.floatLabelPrice}>
                        {t("pages.admin.eventForm.availablePrice")}
                      </div>
                    ) : isCurrentPhase(index) === styles.nextPhasePrice ? (
                      <div
                        className={[
                          styles.floatLabelPrice,
                          styles.bgBlack,
                        ].join(" ")}
                      >
                        {t("pages.admin.eventForm.nextToSell")}
                      </div>
                    ) : null
                  ) : null}
                </div>
              ))
            : null}
          {toEdit || vc.typeTemplate === "free" ? null : (
            <div className={styles.rowInputPrice}>
              <div className={styles.itemInputPrice}>
                <span className={styles.labelInputPrice}>
                  {t("pages.admin.eventForm.categoryName")}
                </span>
                <Input
                  customStyles={styles.inputPrice}
                  placeholder={t("commons.name")}
                  onChange={changeState("newPriceLabel")}
                  value={vc.newPriceLabel}
                />
              </div>
              {((state?.userInfo?.secret?.mercadopago &&
                state?.userInfo?.secret?.verified) ||
                (!state?.userInfo?.secret?.verified &&
                  state.defaultCurrency === "COP")) &&
              vc.acceptCop ? (
                <div className={styles.itemInputPrice}>
                  <span className={styles.labelInputPrice}>$ COP</span>
                  <Input
                    customStyles={styles.inputPrice}
                    placeholder={t("pages.admin.eventForm.copPrice")}
                    onChange={changeState("newPriceValue")}
                    value={
                      isNaN(parseInt(vc.newPriceValue)) ? "" : vc.newPriceValue
                    }
                    type="number"
                  />
                </div>
              ) : null}
              {((state?.userInfo?.secret?.bitcoin?.walletAddress &&
                state?.userInfo?.secret?.verified) ||
                (!state?.userInfo?.secret?.verified &&
                  state.defaultCurrency === "USD")) &&
              vc.acceptUsd ? (
                <div className={styles.itemInputPrice}>
                  <span className={styles.labelInputPrice}>$ USD</span>
                  <Input
                    customStyles={styles.inputPrice}
                    placeholder={t("pages.admin.eventForm.usdPrice")}
                    onChange={changeState("newUsdPriceValue")}
                    value={
                      isNaN(parseInt(vc.newUsdPriceValue))
                        ? ""
                        : vc.newUsdPriceValue
                    }
                    type="number"
                  />
                </div>
              ) : null}
              <div className={styles.itemInputPrice}>
                <span className={styles.labelInputPrice}>
                  {t("pages.admin.eventForm.availableQuantity")}
                </span>
                <Input
                  customStyles={styles.inputPrice}
                  placeholder={t("pages.admin.eventForm.quantity")}
                  onChange={changeState("newPriceStock")}
                  value={
                    isNaN(parseInt(vc.newPriceStock)) ? "" : vc.newPriceStock
                  }
                  type="number"
                />
              </div>

              <button className={styles.buttonPrice} onClick={onAddPrice}>
                <CheckCircle />
              </button>
            </div>
          )}
        </div>
        {state?.userInfo?.secret?.verified ||
        vc.typeTemplate === "free" ? null : (
          <div
            className={styles.linkSecretButton}
            onClick={() => window.open("/profile", "_blank")}
          >
            {t("pages.admin.eventForm.inYourProfileLink")}
          </div>
        )}
      </>
    );
  };

  const returnImage = (image) => {
    return pendingPhotos?.[image]?.preview || image;
  };

  const renderArtists = () => {
    return (
      <div className={styles.contPrices}>
        {vc.artists.length > 0
          ? vc.artists.map((artist, index) => (
              <div key={"item-price-" + index} className={styles.rowInfoPrice}>
                <img
                  src={
                    returnImage(artist.image) || vc.imageLogoPreview || Avatar
                  }
                  alt="artist"
                  className={styles.avatarPrice}
                />
                <p className={styles.textInfoPrice}>{artist?.name}</p>
                <p className={styles.textAddedLink}>
                  {artist?.link ? t("pages.admin.eventForm.linkAdded") : ""}
                </p>
                {/* <p className={styles.textInfoPrice}>{artist?.subtitle}</p> */}
                {index + 1 >= vc.artists.length ? null : (
                  <button
                    className={[
                      styles.buttonMoveItem,
                      styles.buttonMoveDownItem,
                    ].join(" ")}
                    onClick={() => moveArtist(index, 1)}
                  >
                    <ArrowDownward />
                  </button>
                )}
                {index <= 0 ? null : (
                  <button
                    className={[
                      styles.buttonMoveItem,
                      styles.buttonMoveUpItem,
                    ].join(" ")}
                    onClick={() => moveArtist(index, -1)}
                  >
                    <ArrowUpward />
                  </button>
                )}
                <button
                  className={styles.buttonInfoPrice}
                  onClick={() => popArtist(index)}
                >
                  <Close />
                </button>
              </div>
            ))
          : null}

        <p className={styles.labelInput}>
          <br />
          {t("pages.admin.eventForm.addNewArtist")}
        </p>

        <div className={styles.rowInputPrice}>
          <Input
            customStyles={styles.inputPrice}
            placeholder={t("pages.admin.eventForm.urlPhoto")}
            onChange={(e) => {
              e.persist();
              sizeImage(e.target.files[0], 5 * 1024 * 1024, () => {
                changeVc({ newArtistImage: e.target.value });
                const reader = new FileReader();
                reader.onload = () => {
                  setPendingPhotos((ov) => ({
                    ...ov,
                    [e.target.value]: {
                      preview: reader.result,
                      value: e.target.files[0],
                    },
                  }));
                };
                reader.readAsDataURL(e.target.files[0]);
              });
            }}
            value={vc.newArtistImage}
            type="file"
            accept="image/*"
          />
          <Input
            customStyles={styles.inputPrice}
            placeholder={t("commons.name")}
            onChange={changeState("newArtistTitle")}
            value={vc.newArtistTitle}
          />
          <Input
            customStyles={styles.inputPrice}
            placeholder="Link (E: https://fravents.com)"
            onChange={changeState("newArtistLink")}
            value={vc.newArtistLink}
            // placeholder="Subtítulo"
            // onChange={changeState("newArtistSubtitle")}
            // value={vc.newArtistSubtitle}
          />
          <button className={styles.buttonPrice} onClick={onAddArtist}>
            <CheckCircle />
          </button>
        </div>
      </div>
    );
  };

  const onPreview = () => {
    setShowPreview((ov) => !ov);
  };

  const returnPreviewImgArtists = (artists = []) => {
    let previewData = [...artists].map((artist) => ({
      ...artist,
      image: pendingPhotos?.[artist?.image]?.preview || artist?.image,
    }));

    return previewData;
  };

  const resolveDataArtists = (artists = [], uidEvent = "") =>
    new Promise(async (resolve, reject) => {
      try {
        let previewData = [...artists];
        const allPromises = previewData.map(
          (artist, index) =>
            new Promise((res, rej) => {
              if (pendingPhotos?.[artist?.image]?.preview) {
                EventsService.updateArtistImage({
                  uid: state.uid,
                  eventUid: uidEvent,
                  artist: index,
                  image: pendingPhotos?.[artist?.image]?.value,
                })
                  .then((refUrl) => {
                    previewData[index] = {
                      ...previewData[index],
                      image: refUrl,
                    };
                    res(refUrl);
                  })
                  .catch(rej);
              } else {
                res();
              }
            })
        );

        await Promise.all(allPromises);

        resolve(previewData);
      } catch (e) {
        reject(e);
      }
    });

  const onSend = async () => {
    try {
      Model.setData("dialog", { open: false });
      Model.setData("loading", true);

      const isFree = vc.prices[0]?.price === 0 && vc.prices[0]?.usdPrice === 0;

      const uidEvent = await EventsService.createEvent({
        artists: vc.artists,
        date: vc.date,
        dateEnd: vc.dateEnd,
        coords: {
          lat: parseFloat(vc.coords.split(",")[0]),
          lon: parseFloat(vc.coords.split(",")[1]),
        },
        description: vc.description,
        location: vc.location,
        name: vc.name,
        totalPrices: vc.prices,
        prices: vc.prices,
        private: vc.private,
        showPromoters: vc.showPromoters,
        enablePromoters: vc.enablePromoters,
        status: vc.status,
        terms: vc.terms,
        fbPx: vc.fbPx,
        clientPayTax: vc.clientPayTax,
        acceptUsd: isFree ? true : vc.acceptUsd,
        acceptCop: isFree ? true : vc.acceptCop,
        saleWithPhases: vc.saleWithPhases,
        hideAddress: vc.hideAddress,
        acceptOnlinePayments: vc.acceptOnlinePayments,
        isOlder18: vc.isOlder18,
        type: selectedType,
      });

      const artistsData = await resolveDataArtists(vc.artists, uidEvent);
      const referenceFile = await EventsService.updateEventImages(
        state.uid,
        uidEvent,
        vc.imageBanner,
        vc.logo,
        vc.imageDescription
      );

      await EventsService.updateEvent({
        eventUid: uidEvent,
        data: {
          imageBanner: referenceFile[0],
          logo: referenceFile[1],
          artists: artistsData,
          imageDescription: referenceFile[2],
        },
      });

      Model.setData("eventSelected", null);
      Model.updateAlerts({
        message: t("pages.admin.eventForm.successCreateEvent"),
        variant: "success",
      });
      history.push("/admin");
    } catch (e) {
      Model.updateAlerts({
        message: `${t("pages.admin.eventForm.errorCreateEvent")}:  ${e}`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onEdit = async () => {
    try {
      Model.setData("dialog", { open: false });
      Model.setData("loading", true);
      const isFree = vc.prices[0]?.price === 0 && vc.prices[0]?.usdPrice === 0;
      const artistsData = await resolveDataArtists(vc.artists, beforeData.uid);

      await EventsService.updateEvent({
        eventUid: beforeData.uid,
        data: {
          artists: artistsData,
          date: vc.date,
          dateEnd: vc.dateEnd,
          coords: {
            lat: parseFloat(vc.coords.split(",")[0]),
            lon: parseFloat(vc.coords.split(",")[1]),
          },
          description: vc.description,
          location: vc.location,
          name: vc.name,
          prices: vc.prices,
          private: vc.private,
          showPromoters: vc.showPromoters,
          enablePromoters: vc.enablePromoters,
          status: vc.status,
          terms: vc.terms,
          fbPx: vc.fbPx,
          clientPayTax: vc.clientPayTax,
          acceptUsd: isFree ? true : vc.acceptUsd,
          acceptCop: isFree ? true : vc.acceptCop,
          saleWithPhases: vc.saleWithPhases,
          hideAddress: vc.hideAddress,
          acceptOnlinePayments: vc.acceptOnlinePayments,
          isOlder18: vc.isOlder18,
          type: selectedType,
        },
      });
      if (vc.imageBanner || vc.logo || vc.imageDescription) {
        const referenceFile = await EventsService.updateEventImages(
          state.uid,
          beforeData.uid,
          vc.imageBanner,
          vc.logo,
          vc.imageDescription
        );
        const data2save = {
          imageBanner: referenceFile[0],
          logo: referenceFile[1],
          imageDescription: referenceFile[2],
        };
        if (!vc.imageBanner) {
          delete data2save.imageBanner;
        }
        if (!vc.logo) {
          delete data2save.logo;
        }
        if (!vc.imageDescription) {
          delete data2save.imageDescription;
        }
        await EventsService.updateEvent({
          eventUid: beforeData.uid,
          data: data2save,
        });
      }
      Model.setData("eventSelected", null);
      Model.updateAlerts({
        message: t("pages.admin.eventForm.successSaveEvent"),
        variant: "success",
      });
      history.push("/admin");
    } catch (e) {
      Model.updateAlerts({
        message: `${t("pages.admin.eventForm.errorSaveEvent")}:  ${e}`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const beforeSend = () => {
    Model.setData("dialog", {
      open: true,
      title: t("pages.admin.eventForm.saveEvent"),
      text: t("pages.admin.eventForm.areYouSureSaveEvent"),
      txtLeft: t("commons.no"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yes"),
      fnRight: beforeData?.uid ? onEdit : onSend,
    });
  };

  const renderForm = () => {
    if (selectedStep === 0) {
      return (
        <>
          <p
            className={[styles.labelInput, vc.errors?.name ? "error" : ""].join(
              " "
            )}
          >
            {t("pages.admin.eventForm.eventName")} *
          </p>
          <Input
            fullWidth
            customStyles={styles.textInput}
            placeholder={t("pages.admin.eventForm.whatIsEventName")}
            onChange={changeState("name")}
            value={vc.name}
          />

          <div className={styles.row}>
            <div className={styles.itemRowForm}>
              <p
                className={[
                  styles.labelInput,
                  vc.errors?.imageBanner ? "error" : "",
                ].join(" ")}
              >
                {t("pages.admin.eventForm.poster")} *
              </p>

              <Tooltip arrow title={t("pages.admin.eventForm.maxFileSize")}>
                <input
                  className={styles.fileInput}
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    sizeImage(e.target.files[0], 5 * 1024 * 1024, () => {
                      const reader = new FileReader();
                      reader.onload = () => {
                        changeVc({ imageBannerPreview: reader.result });
                      };
                      reader.readAsDataURL(e.target.files[0]);
                      changeVc({ imageBanner: e.target.files[0] });
                    })
                  }
                />
              </Tooltip>
            </div>
            <div className={styles.itemRowForm}>
              <p
                className={[
                  styles.labelInput,
                  vc.errors?.date ? "error" : "",
                ].join(" ")}
              >
                {t("pages.admin.eventForm.dateHourStart")} *
              </p>
              <TextField
                label={t("pages.admin.eventForm.select")}
                type="datetime-local"
                value={moment(vc.date, "x").format("YYYY-MM-DDTHH:mm")}
                className={styles.dateInput}
                onChange={changeState("date")}
                inputProps={{ min: moment().format("YYYY-MM-DDTHH:mm") }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <p className={styles.labelInput}>
                {t("pages.admin.eventForm.dateHourEnd")}
              </p>
              <TextField
                disabled={!vc.date}
                label={t("pages.admin.eventForm.select")}
                type="datetime-local"
                value={moment(vc.dateEnd, "x").format("YYYY-MM-DDTHH:mm")}
                className={styles.dateInput}
                onChange={changeState("dateEnd")}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min: moment(vc.date, "x").format("YYYY-MM-DDTHH:mm"),
                  },
                }}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.itemRowForm}>
              <p className={styles.labelInput}>
                {t("pages.admin.eventForm.address")}
              </p>
              <Autocomplete
                apiKey="AIzaSyDOwLfXIhYWuijRswZexJe5d0JDZPBM7dY&callback=Function.prototype"
                onPlaceSelected={(place) => {
                  changeVc({
                    location: place.formatted_address,
                    coords: `${place?.geometry?.location
                      ?.lat?.()
                      ?.toFixed(7)}, ${place?.geometry?.location
                      ?.lng?.()
                      ?.toFixed(7)}`,
                  });
                }}
                options={{
                  types: ["geocode"],
                  toEdit: false,
                }}
                className={styles.autocompleteTextInput}
                defaultValue={vc.location}
                onChange={(e) => {
                  const func = changeState("location");
                  func(e);
                  changeVc({ coords: "" });
                }}
              />
              {/* )} */}
            </div>
            <div className={styles.itemRowForm}>
              <p className={styles.labelInput}>
                {t("pages.admin.eventForm.hideAddress")}
              </p>
              <div className={styles.rowButtons}>
                <button
                  className={[
                    styles.buttonRow,
                    vc.hideAddress ? styles.bgGreen : "",
                  ].join(" ")}
                  onClick={() =>
                    changeVc({
                      hideAddress: true,
                    })
                  }
                >
                  {t("commons.yes")}
                </button>
                <button
                  className={[
                    styles.buttonRow,
                    !vc.hideAddress ? styles.bgRed : "",
                  ].join(" ")}
                  onClick={() => changeVc({ hideAddress: false })}
                >
                  {t("commons.no")}
                </button>
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.itemRowForm}>
              <p className={styles.labelInput}>
                {t("pages.admin.eventForm.isFor18olld")}
              </p>
              <div className={styles.rowButtons}>
                <button
                  className={[
                    styles.buttonRow,
                    vc.isOlder18 ? styles.bgGreen : "",
                  ].join(" ")}
                  onClick={() =>
                    changeVc({
                      isOlder18: true,
                    })
                  }
                >
                  {t("commons.yes")}
                </button>
                <button
                  className={[
                    styles.buttonRow,
                    !vc.isOlder18 ? styles.bgRed : "",
                  ].join(" ")}
                  onClick={() => changeVc({ isOlder18: false })}
                >
                  {t("commons.no")}
                </button>
              </div>
            </div>
            <div className={styles.itemRowForm}>
              <p
                className={[
                  styles.labelInput,
                  vc.errors?.selectedType ? "error" : "",
                ].join(" ")}
              >
                {t("pages.admin.eventForm.eventType")} *
              </p>
              <div className={styles.containerTypes}>
                <TypesEvent
                  align="left"
                  onClick={changeTypeFilter}
                  value={selectedType}
                  hideAll
                />
              </div>
            </div>
          </div>
        </>
      );
    }
    if (selectedStep === 1) {
      return (
        <>
          <p className={styles.labelInput}>
            {t("pages.admin.eventForm.description")}
          </p>
          <textarea
            className={styles.textArea}
            placeholder={t("pages.admin.eventForm.moreAboutEvent")}
            onChange={changeState("description")}
            value={vc.description}
          />
          <p className={styles.labelInput}>
            {t("pages.admin.eventForm.descriptionImage")}
          </p>

          <Tooltip arrow title={t("pages.admin.eventForm.maxFileSize")}>
            <input
              className={styles.fileInput}
              type="file"
              accept="image/*"
              onChange={(e) =>
                sizeImage(e.target.files[0], 5 * 1024 * 1024, () => {
                  const reader = new FileReader();
                  reader.onload = () => {
                    changeVc({ imageDescriptionPreview: reader.result });
                  };
                  reader.readAsDataURL(e.target.files[0]);
                  changeVc({ imageDescription: e.target.files[0] });
                })
              }
            />
          </Tooltip>
          <p className={styles.labelInput}>{t("commons.artists")}</p>
          {renderArtists()}
        </>
      );
    }
    if (selectedStep === 2) {
      return (
        <>
          <p
            className={[
              styles.labelInput,
              vc.errors?.terms ? "error" : "",
            ].join(" ")}
          >
            {t("pages.admin.eventForm.termsConditionsEvent")} *
          </p>
          <textarea
            className={styles.textArea}
            placeholder={t("pages.admin.eventForm.textAddedTerms")}
            onChange={changeState("terms")}
            value={vc.terms}
          />
          <p
            className={[
              styles.labelInput,
              vc.errors?.prices ? "error" : "",
            ].join(" ")}
          >
            {t("pages.admin.eventForm.prices")} *
          </p>
          <p className={styles.sublabelInput}>
            {toEdit || vc.typeTemplate === "free"
              ? t("pages.admin.eventForm.noEditPrices")
              : state?.userInfo?.secret?.verified
              ? t("pages.admin.eventForm.moneySendToYourAccounts")
              : t("pages.admin.eventForm.toSellMustLinkAccount")}
          </p>
          {!toEdit &&
          state?.userInfo?.secret?.verified &&
          vc.typeTemplate !== "free" &&
          state?.userInfo?.secret?.mercadopago &&
          state?.userInfo?.secret?.bitcoin?.walletAddress ? (
            <div className={styles.row}>
              <div className={styles.itemRowForm}>
                <p className={styles.labelInput}>
                  {t("pages.admin.eventForm.acceptCop")}
                </p>
                <div className={styles.rowButtons}>
                  <button
                    className={[
                      styles.buttonRow,
                      vc.acceptCop === false ? styles.bgRed : "",
                    ].join(" ")}
                    onClick={() => changeAcceptCurrency("acceptCop", false)}
                  >
                    {t("commons.no")}
                  </button>
                  <button
                    className={[
                      styles.buttonRow,
                      vc.acceptCop === true ? styles.bgGreen : "",
                    ].join(" ")}
                    onClick={() => changeAcceptCurrency("acceptCop", true)}
                  >
                    {t("commons.yes")}
                  </button>
                </div>
              </div>
              <div className={styles.itemRowForm}>
                <p className={styles.labelInput}>
                  {t("pages.admin.eventForm.acceptUsd")}
                </p>
                <div className={styles.rowButtons}>
                  <button
                    className={[
                      styles.buttonRow,
                      vc.acceptUsd === false ? styles.bgRed : "",
                    ].join(" ")}
                    onClick={() => changeAcceptCurrency("acceptUsd", false)}
                  >
                    {t("commons.no")}
                  </button>
                  <button
                    className={[
                      styles.buttonRow,
                      vc.acceptUsd === true ? styles.bgGreen : "",
                    ].join(" ")}
                    onClick={() => changeAcceptCurrency("acceptUsd", true)}
                  >
                    {t("commons.yes")}
                  </button>
                </div>
              </div>
            </div>
          ) : null}

          {toEdit || vc.typeTemplate === "free" || true ? null : (
            <p className={styles.labelInput}>
              <br />
              {t("pages.admin.eventForm.addNewPrice")}{" "}
              <span
                className={styles.linkLabelInput}
                onClick={() => history.push("/profile?tab=3")}
              >
                (Change default currency)
              </span>
            </p>
          )}
          {renderPrices()}
          {vc.prices.length > 1 && !toEdit && !vc.typeTemplate ? (
            <div className={styles.itemRowForm}>
              <p className={styles.labelInput}>
                {t("pages.admin.eventForm.sellStagesOrAll")} *
              </p>
              <p className={styles.sublabelInput}>
                {t("pages.admin.eventForm.ifIsStages")}
              </p>
              <div className={styles.rowButtons}>
                <button
                  className={[
                    styles.buttonRow,
                    vc.saleWithPhases === false ? styles.bgGreen : "",
                  ].join(" ")}
                  onClick={() => changeVc({ saleWithPhases: false })}
                >
                  {t("pages.admin.eventForm.allAvailable")}
                </button>
                <button
                  className={[
                    styles.buttonRow,
                    vc.saleWithPhases === true ? styles.bgRed : "",
                  ].join(" ")}
                  onClick={() => changeVc({ saleWithPhases: true })}
                >
                  {t("pages.admin.eventForm.byStages")}
                </button>
              </div>
            </div>
          ) : null}
          {state?.userInfo?.secret?.verified && vc.typeTemplate !== "free" ? (
            <>
              <div className={styles.row}>
                <div className={styles.itemRowForm}>
                  <p className={styles.labelInput}>
                    {t("pages.admin.eventForm.activatePayments")}
                  </p>
                  <div className={styles.rowButtons}>
                    <button
                      className={[
                        styles.buttonRow,
                        vc.acceptOnlinePayments ? styles.bgGreen : "",
                      ].join(" ")}
                      onClick={() =>
                        changeVc({
                          acceptOnlinePayments: true,
                        })
                      }
                    >
                      {t("commons.yes")}
                    </button>
                    <button
                      className={[
                        styles.buttonRow,
                        !vc.acceptOnlinePayments ? styles.bgRed : "",
                      ].join(" ")}
                      onClick={() => changeVc({ acceptOnlinePayments: false })}
                    >
                      {t("commons.no")}
                    </button>
                  </div>
                </div>
                {vc.acceptOnlinePayments ? (
                  <div className={styles.itemRowForm}>
                    <p className={styles.labelInput}>
                      {t("pages.admin.eventForm.pricesHaveTax")} *
                    </p>
                    <div className={styles.rowButtons}>
                      <button
                        className={[
                          styles.buttonRow,
                          vc.clientPayTax === false ? styles.bgGreen : "",
                        ].join(" ")}
                        onClick={() => changeVc({ clientPayTax: false })}
                      >
                        {t("pages.admin.eventForm.yesIncluded")}
                      </button>
                      <button
                        className={[
                          styles.buttonRow,
                          vc.clientPayTax === true ? styles.bgRed : "",
                        ].join(" ")}
                        onClick={() => changeVc({ clientPayTax: true })}
                      >
                        {t("pages.admin.eventForm.noClientPay")}
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </>
      );
    }
    if (selectedStep === 3) {
      return (
        <>
          <p className={styles.labelInput}>
            {t("pages.admin.eventForm.graphicResources")}
          </p>
          <div className={styles.rowButtons}>
            <a
              download="Logo-Fravents-Negro.png"
              className={styles.buttonSourceLogoBlack}
              href={require("../../assets/images/Logo-Fravents-Negro.png")}
            >
              <img
                alt="Fravents"
                src={require("../../assets/images/Logo-Fravents-Negro.png")}
                className={styles.logoSource}
              />
              <p className={styles.labelLogo}>{t("commons.download")}</p>
            </a>
            <a
              download="Logo-Fravents-blanco.png"
              href={require("../../assets/images/Logo-Fravents-blanco.png")}
              className={[styles.buttonSourceLogoBlack, styles.logoWhite].join(
                " "
              )}
            >
              <img
                alt="Fravents"
                src={require("../../assets/images/Logo-Fravents-blanco.png")}
                className={styles.logoSource}
              />
              <p className={styles.labelLogo}>{t("commons.download")}</p>
            </a>
          </div>

          <div className={styles.row}>
            <div className={styles.itemRowForm}>
              <p className={styles.labelInput}>
                {t("pages.admin.eventForm.availablePromoters")}
              </p>
              <div className={styles.rowButtons}>
                <button
                  className={[
                    styles.buttonRow,
                    vc.enablePromoters ? styles.bgGreen : "",
                  ].join(" ")}
                  onClick={() => changeVc({ enablePromoters: true })}
                >
                  {t("commons.yes")}
                </button>
                <button
                  className={[
                    styles.buttonRow,
                    !vc.enablePromoters ? styles.bgRed : "",
                  ].join(" ")}
                  onClick={() => changeVc({ enablePromoters: false })}
                >
                  {t("commons.no")}
                </button>
              </div>
            </div>
            <div className={styles.itemRowForm}>
              {vc.enablePromoters ? (
                <>
                  <p className={styles.labelInput}>
                    {t("pages.admin.eventForm.wantPromotersVisible")}
                  </p>
                  <div className={styles.rowButtons}>
                    <button
                      className={[
                        styles.buttonRow,
                        vc.showPromoters ? styles.bgGreen : "",
                      ].join(" ")}
                      onClick={() => changeVc({ showPromoters: true })}
                    >
                      {t("commons.yes")}
                    </button>
                    <button
                      className={[
                        styles.buttonRow,
                        !vc.showPromoters ? styles.bgRed : "",
                      ].join(" ")}
                      onClick={() => changeVc({ showPromoters: false })}
                    >
                      {t("commons.no")}
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.itemRowForm}>
              <p
                className={[
                  styles.labelInput,
                  vc.errors?.private ? "error" : "",
                ].join(" ")}
              >
                {t("pages.admin.eventForm.isPrivate")} *
              </p>
              <div className={styles.rowButtons}>
                <button
                  className={[
                    styles.buttonRow,
                    vc.private === true ? styles.bgGreen : "",
                  ].join(" ")}
                  onClick={() => changeVc({ private: true })}
                >
                  {t("pages.admin.eventForm.yesPrivate")}
                </button>
                <button
                  className={[
                    styles.buttonRow,
                    vc.private === false ? styles.bgRed : "",
                  ].join(" ")}
                  onClick={() => changeVc({ private: false })}
                >
                  {t("pages.admin.eventForm.noPublic")}
                </button>
              </div>
            </div>
            {showStatus ? (
              <div className={styles.itemRowForm}>
                <p
                  className={[
                    styles.labelInput,
                    vc.errors?.status ? "error" : "",
                  ].join(" ")}
                >
                  {t("pages.admin.eventForm.wantPermanentVisible")} *
                </p>
                <div className={styles.rowButtons}>
                  <button
                    className={[
                      styles.buttonRow,
                      vc.status === "published" ? styles.bgGreen : "",
                    ].join(" ")}
                    onClick={() => changeVc({ status: "published" })}
                  >
                    {t("pages.admin.eventForm.yesVisible")}
                  </button>
                  <button
                    className={[
                      styles.buttonRow,
                      vc.status === "draft" ? styles.bgRed : "",
                    ].join(" ")}
                    onClick={() => changeVc({ status: "draft" })}
                  >
                    {t("pages.admin.eventForm.noHide")}
                  </button>
                </div>
              </div>
            ) : null}
          </div>

          <div className={styles.row}>
            <div className={styles.itemRowForm}>
              <p className={styles.labelInput}>
                {t("pages.admin.eventForm.addFbPixel")}
              </p>
              <div className={styles.rowButtons}>
                <button
                  className={[
                    styles.buttonRow,
                    vc.showFbPixel === true ? styles.bgGreen : "",
                  ].join(" ")}
                  onClick={() => changeVc({ showFbPixel: true, fbPx: "" })}
                >
                  {t("commons.yes")}
                </button>
                <button
                  className={[
                    styles.buttonRow,
                    vc.showFbPixel === false ? styles.bgRed : "",
                  ].join(" ")}
                  onClick={() => changeVc({ showFbPixel: false, fbPx: "" })}
                >
                  {t("commons.no")}
                </button>
              </div>
            </div>
            {vc.showFbPixel ? (
              <div className={styles.itemRowForm}>
                <p className={styles.labelInput}>
                  {t("pages.admin.eventForm.fbPixel")}
                </p>
                <Input
                  fullWidth
                  customStyles={styles.textInput}
                  placeholder="1234567890"
                  onChange={changeState("fbPx")}
                  value={vc.fbPx}
                />
              </div>
            ) : null}
          </div>
        </>
      );
    }
  };

  const callEventTemplate = (typeTemplate, valueVc) => {
    switch (typeTemplate) {
      case "free":
        setVc({
          ...valueVc,
          typeTemplate,
          clientPayTax: false,
          acceptUsd: false,
          acceptCop: false,
          saleWithPhases: false,
          prices: [
            {
              price: 0,
              stock: 0,
              label: t("commons.free"),
            },
          ],
        });
        break;

      case "byTypes":
        setVc({
          ...valueVc,
          typeTemplate,
          saleWithPhases: true,
        });
        break;
      case "forAll":
        setVc({
          ...valueVc,
          typeTemplate,
          saleWithPhases: false,
        });
        break;

      default:
    }
  };

  const firstLoad = () => {
    if (beforeData?.uid) {
      Model.setData("eventSelected", beforeData);
      setVc({
        ...beforeData,
        coords: beforeData?.coords?.lat
          ? `${beforeData.coords.lat}, ${beforeData?.coords?.lon}`
          : "",
        imageBanner: "",
        imageDescription: "",
        logo: "",
        newPriceLabel: "",
        newPriceValue: "",
        newUsdPriceValue: "",
        newPriceStock: "",
        newArtistTitle: "",
        newArtistSubtitle: "",
        newArtistLink: "",
        newArtistImage: "",
        imageBannerPreview:
          beforeData?.imageBanner || beforeData?.imageBannerPreview || "",
        imageDescriptionPreview:
          beforeData?.imageDescription ||
          beforeData?.imageDescriptionPreview ||
          "",
        imageLogoPreview:
          beforeData?.logo || beforeData?.imageLogoPreview || "",
        showFbPixel: !!beforeData?.fbPx,
      });
      if (beforeData?.status === "published") {
        setShowStatus(false);
      }
      if (beforeData?.type) {
        setSelectedType(beforeData.type);
      }
    } else {
      callEventTemplate(state.selectedEventTemplate || "forAll", { ...vc });
    }
  };

  const loadTestData = () => {
    setVc((ov) => ({
      ...ov,
      artists: [
        {
          image:
            "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png",
          name: "DJ ONE",
          sublabel: "Colombia",
        },
      ],
      date: moment().add(360, "day").format("x"),
      dateEnd: moment().add(365, "day").format("x"),
      coords: "4.6486619, -74.0609311",
      description: "Lorem ipsum",
      imageBanner: "",
      imageDescription: "",
      location: "Bogota, colombia",
      logo: "",
      name: "Test event info",
      prices:
        ov.prices.length < 1
          ? [{ label: "Precio 1", price: 30000, stock: 15 }]
          : ov.prices,
      private: null,
      showPromoters: false,
      enablePromoters: false,
      hideAddress: false,
      isOlder18: false,
      status: "",
      terms: "Long terms test",
      newPriceLabel: "",
      newPriceValue: "",
      newUsdPriceValue: "",
      newPriceStock: "",
      newArtistTitle: "",
      newArtistSubtitle: "",
      newArtistLink: "",
      newArtistImage: "",
      imageBannerPreview: "",
      imageDescriptionPreview: "",
      imageLogoPreview: "",
      clientPayTax: false,
      acceptOnlinePayments: false,
      acceptUsd:
        beforeData?.acceptUsd ||
        !!state?.userInfo?.secret?.bitcoin?.walletAddress,
      acceptCop: toEdit
        ? !beforeData?.acceptCop && !beforeData?.acceptUsd
          ? true
          : beforeData?.acceptCop
        : !!state?.userInfo?.secret?.mercadopago,
      showFbPixel: false,
      selectedType: "",
    }));
  };

  React.useEffect(() => {
    if (
      (state.selectedEventTemplate ||
        process.env.NODE_ENV === "development" ||
        toEdit) &&
      !beforeData?.isCancelled
    ) {
      firstLoad();
    } else {
      history.push("/admin");
    }
    return () => {};
    //eslint-disable-next-line
  }, []);

  return (
    <Layout blackHeader>
      <div className={styles.row}>
        <div className={styles.leftContainer}>
          <div className={styles.rowSteps}>
            {steps.map((step, index) => (
              <button
                key={step.button}
                className={[
                  styles.stepButton,
                  selectedStep === index ? styles.selectedStep : "",
                  returnHasError(index).hasErrors && touchedStep[index]
                    ? styles.errorStep
                    : "",
                ].join(" ")}
                onClick={() => {
                  setTouchedStep((prev) => {
                    const newTouched = [...prev];
                    newTouched[selectedStep] = true;
                    return newTouched;
                  });
                  setSelectedStep(index);
                }}
              >
                {step.button}
              </button>
            ))}
          </div>
          {process.env.NODE_ENV === "development" ? (
            <button className={styles.loadTestButton} onClick={loadTestData}>
              {t("pages.admin.eventForm.loadTestData")}
            </button>
          ) : null}
          <div className={styles.row}>
            <h1 className={styles.titleForm}>{steps[selectedStep].label}</h1>
            <button className={styles.previewButton} onClick={onPreview}>
              <Visibility />
              <span>{t("pages.admin.eventForm.preview")}</span>
            </button>
          </div>
          <div className={styles.containerForm}>{renderForm()}</div>
          <div className={styles.rowMoveButtons}>
            {selectedStep > 0 ? (
              <button
                className={styles.beforeButton}
                onClick={() => setSelectedStep((ov) => ov - 1)}
              >
                {t("pages.admin.eventForm.before")}
              </button>
            ) : (
              <div />
            )}
            {toEdit && selectedStep < 3 ? (
              <button
                className={styles.nextButton}
                onClick={() => validateForm(beforeSend, true)}
              >
                {t("pages.admin.eventForm.save")}
              </button>
            ) : null}
            <button
              className={styles.nextButton}
              onClick={() => validateForm(beforeSend)}
            >
              {selectedStep === 3
                ? t("pages.admin.eventForm.save")
                : t("pages.admin.eventForm.next")}
            </button>
          </div>
        </div>
        <div
          className={[
            styles.rightContainer,
            showPreview ? styles.showPreview : "",
          ].join(" ")}
        >
          <div className={styles.contPreview}>
            <div className={styles.rowHeaderPreview}>
              <span>{t("pages.admin.eventForm.preview")}</span>
              <button onClick={onPreview}>
                <Close />
              </button>
            </div>
            <BannerEvent
              eventInfo={{
                ...vc,
                imageBanner: vc.imageBannerPreview,
              }}
              changeVc={() => {}}
              isMobile
            />
            <ContentEvent
              eventInfo={{
                ...vc,
                imageDescription: vc.imageDescriptionPreview,
              }}
              artists={returnPreviewImgArtists(vc.artists)}
              isMobile
              isPreview
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EventForm;
